import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import { dashboardsID, payerDashboardID } from "utils/modulesIds";
import PageBreadCrumbAndTitle from "components/TP/Common/General/PageBreadCrumbAndTitle";
import { CardUser, PieChart } from "components/TP/dashboards";
import { NotificationMessage, apiErrorrHandler, decryptText } from "utils";
import { useSelector } from "react-redux";
import { APIDateFormat } from "constants/TPA";
import moment from "moment";
import dayjs from "dayjs";
import ProviderChart from "components/TP/dashboards/ProviderChart";
import { showDashboard, showKPI } from "helpers/General/dashboards_helper";
import MedicalCodeChart from "components/TP/dashboards/MedicalCodeChart";
import ClaimsAmount from "components/TP/dashboards/CliamsAmount";
import { ModalSpinner } from "components/TP/Common/Forms";
import ActiveMembers from "components/TP/dashboards/ActiveMembers";
import ClaimOverview from "components/TP/dashboards/ClaimOverview";
import BusinessOverView from "components/TP/dashboards/BusinessOverView";
import LossRatio from "components/TP/dashboards/LossRatio";

const PayerDashboard = (props) => {
  // main payer
  const mainPayer = JSON.parse(decryptText(localStorage.getItem("mainPayer")));

  const { module } = useSelector((state) => ({
    module: state.Sidebar?.modules
      ?.find((item) => item.id === dashboardsID)
      ?.sub_modules?.find((item) => item.id === payerDashboardID),
    loading: state.Sidebar?.loading,
  }));

  const dataColors =
    '["--bs-primary", "--bs-success", "--bs-danger" , "--bs-secondary"]';

  const [dashboardKPIs, setDashboardKPIs] = useState([]);
  const [dateRange, setDateRange] = useState({
    start_date: dayjs().add(-7, "d").format(APIDateFormat),
    end_date: dayjs().format(APIDateFormat),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [kpiResponses, setKpiResponses] = useState({});
  const [slugs, setSlugs] = useState([]);

  //call dashboard to get all slugs
  const getDashboard = async (dashboardId, dates) => {
    setIsLoading(true);
    try {
      const response = await showDashboard(dashboardId, dates);
      setDashboardKPIs(response.item);
      setSlugs(response.item.map((dashboardItem) => dashboardItem.slug));
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  //call kpi endpoint to get data of one slug
  const getKPI = async (slug, data, lastIndex = false) => {
    setIsLoading(true);
    try {
      const response = await showKPI(slug, {
        start_date: moment(dateRange.start_date)
          .format(APIDateFormat)
          .replaceAll("/", "-"),
        end_date: moment(dateRange.end_date)
          .format(APIDateFormat)
          .replaceAll("/", "-"),
        ...data,
      });

      setKpiResponses((prevKpiResponses) => ({
        ...prevKpiResponses,
        [slug]: response.item,
      }));
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    } finally {
      if (lastIndex) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getDashboard(payerDashboardID, {
      start_date: moment(dateRange.start_date)
        .format(APIDateFormat)
        .replaceAll("/", "-"),
      end_date: moment(dateRange.end_date)
        .format(APIDateFormat)
        .replaceAll("/", "-"),
    });
  }, []);

  useEffect(() => {
    if (mainPayer && slugs.length > 0) {
      getPayerKPI();
    }
  }, [slugs, JSON.stringify(mainPayer)]);

  // get kpi of each slug
  const getPayerKPI = async () => {
    if (mainPayer) {
      try {
        slugs.forEach((slug, index) => {
          let conditionKeys = [];
          let conditionValues = [];

          switch (slug) {
            case "active-policies-count-by-type-and-payer":
            case "loss-ratio-by-policy-type-and-payer":
            case "count-active-beneficiary-by-class":
            case "gender-count-by-policy-date-range":
              conditionKeys = ["payer_id"];
              conditionValues = [mainPayer?.id];
              break;
            case "claim-top-providers-by-amount-and-payer":
            case "claim-top-general-assessment-by-amount-and-payer":
            case "claim-total-amount-by-benefit-and-payer":
            case "retention-ratio-by-payer":
            case "claims-amounts-by-months-and-fob":
              conditionKeys = ["policy.payer_id"];
              conditionValues = [mainPayer?.id];
              break;
            case "business-overview-per-policies-date-status-premiums":
              conditionKeys = [];
              conditionValues = [];
            default:
              null;
              break;
          }

          getKPI(
            slug,
            {
              condition_keys: conditionKeys,
              condition_values: conditionValues,
              ...(slug === "gender-count-by-policy-date-range" && {
                compare_start_date: moment(dateRange.start_date)
                  .subtract(1, "year")
                  .format(APIDateFormat)
                  .replaceAll("/", "-"),
                compare_end_date: moment(dateRange.start_date)
                  .format(APIDateFormat)
                  .replaceAll("/", "-"),
              }),
            },
            index === slugs.length - 1
          );
        });
      } catch (error) {
        const errorMessage = apiErrorrHandler(error);
        NotificationMessage("Error", errorMessage);
      } finally {
        // setIsLoading(false);
      }
    }
  };

  //function to render each compnent
  const renderChart = (kpiKey, ChartComponent) => {
    // kpiResponses[kpiKey] &&
    //   kpiResponses[kpiKey]?.data?.length > 0 &&
    return (
      <Col md={4}>
        {!isLoading && kpiResponses[kpiKey] && (
          <ChartComponent
            KPI={kpiResponses[kpiKey]}
            key={kpiKey}
            dataColors={dataColors}
          />
        )}
      </Col>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <PageBreadCrumbAndTitle title={module?.title} />
          {/* Rounded Corners White Card and Table inside it */}
          <Row>
            {/* Welcome User Card */}
            <CardUser
              setDateRange={setDateRange}
              dateRange={dateRange}
              handleClick={() => getPayerKPI()}
              isLoading={isLoading}
            />
            {/* Loading State */}
            {isLoading ? <ModalSpinner color="primary" /> : null}
          </Row>

          <Row className="mb-3">
            {renderChart(
              "business-overview-per-policies-date-status-premiums",
              BusinessOverView
            )}
            {renderChart("claims-amounts-by-months-and-fob", ClaimOverview)}
            {renderChart("active-policies-count-by-type-and-payer", PieChart)}
            {/* {renderChart("retention-ratio-by-payer", GaugeChart)} */}
            {/* {renderChart("loss-ratio-by-policy-type-and-payer", LossRatio)} */}
            {renderChart("count-active-beneficiary-by-class", ActiveMembers)}
            {renderChart(
              "claim-total-amount-by-benefit-and-payer",
              ClaimsAmount
            )}
            {renderChart(
              "claim-top-providers-by-amount-and-payer",
              ProviderChart
            )}
            {renderChart(
              "claim-top-general-assessment-by-amount-and-payer",
              MedicalCodeChart
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

PayerDashboard.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(PayerDashboard);
