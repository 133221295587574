import React from "react"
import { Link } from "react-router-dom"
import { Badge, Card, CardBody } from "reactstrap"

const UserDetailsCard = () => {
  return (
    <Card>
      <CardBody>
        <div className="text-center">
          <h5 className="mt-3 mb-1">Nadine Kamal Rahhal</h5>
          <p className="text-muted mb-0">
            Since July 2017
            <Badge pill color="success" className="ms-1">
              2,160 days
            </Badge>
          </p>
        </div>

        <ul className="list-unstyled mt-4">
          <li>
            <div className="d-flex">
              <i className="bx bx-phone text-primary fs-4"></i>
              <div className="ms-3">
                <h6 className="fs-14 mb-2">Phone</h6>
                <p className="text-muted fs-14 mb-0">+589 560 56555</p>
              </div>
            </div>
          </li>
          <li className="mt-3">
            <div className="d-flex">
              <i className="bx bx bx-user text-primary fs-4"></i>
              <div className="ms-3">
                <h6 className="fs-14 mb-2">Status</h6>
                <p className="text-muted fs-14 mb-0">
                  Female - Married - Teacher
                </p>
              </div>
            </div>
          </li>
          <li className="mt-3">
            <div className="d-flex">
              <i className="bx bx bx-calendar text-primary fs-4"></i>
              <div className="ms-3">
                <h6 className="fs-14 mb-2">Date Of Birth</h6>
                <p className="text-muted fs-14 text-break mb-0">
                  1985-11-01
                  <Badge className="badge-soft-dark py-2 px-2 ms-3">
                    37 years old
                  </Badge>
                </p>
              </div>
            </div>
          </li>
          <li className="mt-3">
            <div className="d-flex">
              <i className="bx bx-map text-primary fs-4"></i>
              <div className="ms-3">
                <h6 className="fs-14 mb-2">Address</h6>
                <p className="text-muted fs-14 mb-0">
                  Lebanon - Beirut - Down Town
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div className="mt-4">
          <Link to="#" className="btn btn-soft-primary btn-hover w-100 rounded">
            <i className="mdi mdi-eye"></i> View Profile
          </Link>
        </div>
      </CardBody>
    </Card>
  )
}

export default UserDetailsCard
