import React from "react";
import { Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";

const ActiveMembers = ({ KPI }) => {
  const typeNames = [];
  const typeCounts = [];

  KPI?.data?.forEach((item) => {
    item.classes.forEach((clas) => {
      typeNames.push(clas.name);
      typeCounts.push(clas.count);
    });
  });

  const data = typeCounts;

  const options = {
    chart: {
      width: "100px",
      height: "100px",
      type: "pie",
    },
    labels: typeNames,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: ["#556ee6", "#f1b44c", "#f46a6a", "#50a5f1", "#34c38f"],
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">{KPI?.name}</h4>
          <div className="mb-5">
            <ReactApexChart
              type="pie"
              height="300"
              //   width="300px"
              //   height={data && data.length > 0 ? "490px" : "235px"}
              //   width={data && data.length > 0 ? "387px" : "0"}
              options={options}
              series={data}
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ActiveMembers;
