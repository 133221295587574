import PropTypes from "prop-types"
import React, { Fragment, useEffect, useRef } from "react"

// for loading animations
import { Skeleton } from "antd"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { Link, withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

// redux hooks
import { useSelector, useDispatch } from "react-redux"

/* redux actions to get the sidebar content 
that allowed for the logged in user
*/
import { getModules as onGetModules } from "store/actions"
import SidebarItem from "./SidebarItem"

const SidebarContent = props => {
  // initialize dispatch
  const dispatch = useDispatch()

  // getting all redux state for the sidebar

  const { modules, error, loading } = useSelector(state => ({
    modules: state.Sidebar.modules,
    error: state.Sidebar.error,
    loading: state.Sidebar.loading,
  }))

  // getting all modules when the component did mount
  useEffect(() => {
    if (modules.length === 0) {
      dispatch(onGetModules())
    }
  }, [modules.length])

  const ref = useRef()

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  // if (loading) {
  //   return (
  //     <Skeleton
  //       active
  //       paragraph={{
  //         rows: 15,
  //       }}
  //     />
  //   )
  // }
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li>
              <Link to="/pdf-viewer">
                <i className="mdi mdi-file-pdf font-size-24"></i>
                <span>PDF Viewer</span>
              </Link>
            </li> */}

            {modules.length > 0 &&
              modules.map(module => (
                <Fragment key={module.id}>
                  {/* title */}
                  <li className="menu-title">{module.title}</li>

                  {/* sidebar links */}
                  {module.sub_modules.length > 0 &&
                    module.sub_modules.map(sub_module => (
                      <li key={sub_module.id}>
                        <SidebarItem item={sub_module} />
                      </li>
                    ))}
                </Fragment>
              ))}
            {/* <li>
              <Link to="/test">
                <i className="mdi mdi-account-box-multiple font-size-24"></i>
                <span>Editable Table</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
