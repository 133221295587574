import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import {
  AddMessage,
  addConversation,
  getConversations,
  readMessage,
  showConverstation,
  updateConversation,
} from "helpers/General/conversation_helper";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import moment from "moment";
import { NotificationMessage, apiErrorrHandler } from "utils";
import MultiSkeleton from "../Common/MultiSkeleton";
import { buttonsTitles } from "common/data";
import { LoadingModal } from "../Common/Modals";

const ChatModal = ({ show, onCloseClick, beneficiary, claim, resourceId }) => {
  const [error, setError] = useState("");
  const [messageBox, setMessageBox] = useState(null);
  const [message, setMessage] = useState("");
  const [conversation, setConversation] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [IsAdding, setIsAdding] = useState(false);
  const [newName, setNewName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const module_id = useSelector((state) => state?.Module?.id);
  const myAccessType = JSON.parse(localStorage.getItem("authUser"))?.access_type
    ?.id;

  const AddnewConversation = async () => {
    setIsAdding(true);
    try {
      const res = await addConversation({
        name: ` Insurer Chat # ${claim?.reference}`,
        module_id,
        resource_id: resourceId,
        access_type_id: myAccessType, //id of selected accesstype from dropdown
      });
      setSelectedConversation(res?.item?.id);
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    } finally {
      setIsAdding(false);
    }
  };

  //get all conversations of same resource id and module id
  const getAllConversations = async () => {
    setIsLoading(true);
    try {
      const response = await getConversations({
        per_page: 1000,
        page: 1,
        module_id,
        resource_id: resourceId,
        access_type_id: myAccessType !== 1 ? myAccessType : null,
        //if user access type 1 (tpa) get all convs, else get convs of this user access type
      });

      const resConver = response?.items;
      if (resConver?.length > 0) {
        setSelectedConversation(resConver[0]?.id);
      } else {
        AddnewConversation();
      }
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  //get all conversations depending on resourceId
  useEffect(() => {
    if (resourceId) {
      getAllConversations();
    }
  }, [resourceId]);

  //add messagge to ui and db
  const sendMsgSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    try {
      await AddMessage(selectedConversation, {
        message,
        user_id: JSON.parse(localStorage.getItem("authUser"))?.id,
      });
      setMessage("");
      getConversation(selectedConversation);
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      setError(errorMessage);
    } finally {
      setIsSending(false);
    }
  };

  const handleReadMessage = async (messageId) => {
    setIsSaving(true);
    try {
      await readMessage(messageId, {
        is_read: 1,
      });
      NotificationMessage("success", "Message Marked As Read");
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  //update conversation name in db & ui
  const onUpdateSubmit = async (e) => {
    e.preventDefault();
    if (newName.trim() !== "") {
      try {
        await updateConversation(selectedConversation, {
          name: newName,
        });
        setNewName("");
        setIsOpen(false);
        getConversation(selectedConversation);
      } catch (error) {
        const errorMessage = apiErrorrHandler(error);
        NotificationMessage("error", errorMessage);
      }
    }
    setNewName("");
  };

  //show conversation for each one
  const getConversation = async (id) => {
    setIsLoading(true);
    try {
      const response = await showConverstation(id);
      setConversation(response.item);
      setNewName(response.item?.name);
      setError("");
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  //get chat of selected conversation
  useEffect(() => {
    if (selectedConversation) {
      getConversation(selectedConversation);
    }
  }, [selectedConversation]);

  //message of my user id
  const isMyMessage = (userId) => {
    const user = JSON.parse(localStorage.getItem("authUser"))?.id;
    return user === userId ? true : false;
  };

  // check if the message is from TPA user or not
  const isTPAMessage = (user) => {
    const accessTypeId = user?.access_type?.id;
    return accessTypeId === 1 ? true : false;
  };

  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000;
    }
  };

  useEffect(() => {
    if (!isEmpty(conversation?.messages)) scrollToBottom();
  }, [conversation]);

  return (
    <Fragment>
      <Modal
        size="xl"
        isOpen={show}
        toggle={onCloseClick}
        centered
        // className="modal-fullscreen"
      >
        <React.Fragment>
          {/* Modal header */}
          <div
            className="modal-header bg-light bg-opacity-50"
            id="myModalLabel"
          >
            {/* Modal Header Title */}
            <h5 className="modal-title mt-0 ">
              {IsLoading || IsAdding ? (
                <MultiSkeleton length={1} />
              ) : conversation ? (
                conversation.name
              ) : null}
            </h5>
            {/* show update icon if there is a conversation */}
            {conversation ? (
              <ul className="list-inline user-chat-nav text-end mb-0">
                {/* Mark as Read for last Message in conversation */}
                {conversation.messages?.length > 0 && (
                  <li className="list-inline-item d-none d-sm-inline-block">
                    <button
                      type="button"
                      disabled={isSaving}
                      // send the last message of this conversation
                      onClick={() =>
                        handleReadMessage(
                          conversation.messages[
                            conversation.messages.length - 1
                          ]?.id
                        )
                      }
                      className=" btn btn-primary d-flex align-items-center justify-content-center gap-2"
                    >
                      <i className="bx bx bx-check-double font-size-17" />
                      <span>Mark As Read</span>
                    </button>
                  </li>
                )}

                <li className="list-inline-item d-none d-sm-inline-block">
                  <Button
                    onClick={() => setIsOpen(true)}
                    className="bg-transparent  border-0 text-black shadow-none d-flex align-items-center justify-content-center"
                    style={{ paddingRight: "50px" }}
                  >
                    <i className="bx bx-cog font-size-17" />
                  </Button>
                  <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
                    <ModalHeader>Update Conversation name</ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={onUpdateSubmit}
                        className="d-flex flex-column"
                      >
                        <Input
                          type="text"
                          placeholder="Update name"
                          value={newName}
                          onChange={(e) => {
                            setNewName(e.target.value);
                          }}
                          className="ps-2 pr-4 w-100 border-1"
                        />
                        <Button
                          type="submit"
                          className="mt-3 btn-blue align-self-end"
                          disabled={!newName}
                        >
                          {buttonsTitles.update}
                        </Button>
                      </Form>
                    </ModalBody>
                  </Modal>
                </li>
              </ul>
            ) : null}

            {/* close button on the top right section */}
            <button
              aria-label="Close Modal"
              type="button"
              onClick={onCloseClick}
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button>
          </div>

          {/* Conversation container */}
          <div className="d-lg-flex">
            {/* chat  */}
            <div className="w-100 user-chat">
              <div>
                <div className="chat-conversation p-3">
                  <ul className="list-unstyled">
                    <PerfectScrollbar
                      style={{ height: "479px" }}
                      containerRef={(ref) => setMessageBox(ref)}
                    >
                      {conversation &&
                        conversation?.messages.length > 0 &&
                        conversation?.messages
                          ?.sort((a, b) => {
                            return a.id - b.id;
                          })
                          ?.map((msg, index) => (
                            <Fragment key={msg?.id}>
                              <div
                                className={`d-flex ${
                                  isTPAMessage(msg?.user)
                                    ? "justify-content-start flex-row-reverse me-3"
                                    : " justify-content-start"
                                }  gap-1`}
                              >
                                <div
                                  className="card bg-blue bg-opacity-10"
                                  style={
                                    isTPAMessage(msg?.user)
                                      ? {
                                          borderBottomRightRadius: "0rem",
                                        }
                                      : {
                                          borderBottomLeftRadius: "0rem",
                                        }
                                  }
                                >
                                  <div className="card-body p-2">
                                    {/* Message User */}
                                    <h4 className="text-blue">
                                      {isMyMessage(msg?.user?.id)
                                        ? "You"
                                        : msg?.user?.name}
                                    </h4>
                                    {/* MEssage content */}
                                    <p className="mb-1">{msg.message}</p>
                                    {/* message Time */}
                                    <p className="mb-0">
                                      <i className=" bx bx-time font-size-12 me-1" />
                                      <span>
                                        {moment(msg?.created_at).format(
                                          "DD-MM-YYYY "
                                        )}
                                        <span className="time text-danger">
                                          {moment(msg?.created_at).format(
                                            "hh:mm"
                                          )}
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          ))}
                    </PerfectScrollbar>
                  </ul>
                </div>
                {/* show input & send button only if there is a conversation */}
                {conversation ? (
                  <div className="p-3 chat-input-section">
                    <Form onSubmit={sendMsgSubmit}>
                      <div className="d-flex align-items-center gap-4">
                        <Input
                          className="rounded-pill ps-3 bg-blue bg-opacity-10 border-0"
                          type="text"
                          placeholder="Enter message..."
                          value={message}
                          onChange={(e) => {
                            const text = e.target.value;
                            // max length of message is 255 characters
                            if (text.length > 255) return;
                            setMessage(text);
                          }}
                        />
                        {/* Message Characters Count */}
                        {message.length > 0 ? (
                          <div>{message.length}/255</div>
                        ) : null}
                        <Button
                          className="btn btn-blue w-md rounded-pill"
                          type="submit"
                          disabled={!message || isSending ? true : false}
                        >
                          <span className="d-none d-sm-inline-block me-2">
                            {buttonsTitles.send}
                          </span>
                          <i className="bx bx-send font-size-13 "></i>
                        </Button>
                      </div>
                    </Form>
                  </div>
                ) : null}

                {!conversation && IsAdding && <MultiSkeleton length={1} />}
              </div>
            </div>
          </div>
        </React.Fragment>
      </Modal>
      <LoadingModal isOpen={isSaving} />
    </Fragment>
  );
};

export default ChatModal;
