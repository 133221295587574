export const benefitsSteps = [
  "Your CSV data should be in the format below. The first line of your CSV file should be <b>the column headers</b> as in the table example. Also make sure that <b>your file is UTF-8</b> to avoid unnecessary encoding problems.",
  "If the column you are trying to import is date <b>make sure that is formatted in format Y-m-d (2023-04-13).</b>",
  "In the column Benefit Group, you must add either the <b> BENEFIT NAME or the BENEFIT ID</b>, which you can get them by clicking here.",
  "In the column Active, you must add either <span style='font-weight:bold'>yes or no</span> ,small letter",
];

export const beneficiariesSteps = [
  "<b>Column Headers:</b> The first line of your Excel file should consist of the column headers as shown in the table example. Ensure that the names of your column headers remain unchanged to avoid any issues.",
  "<b>Principal Index Matching:</b> Ensure that the principal index matches the index for sub-beneficiaries to correctly link family members or dependent records.",
  "<b>Consistent Indexing:</b> The indices for gender, dependency status, marital status, nationality, and client class must correspond to the preset slugs provided in the system documentation.",
  "<b>Plan Index Consistency:</b> The plan index must be identical to the product plan ID to ensure correct plan assignment.",
  "<b>Unique PIN Numbers:</b> Each insurer must have a unique PIN number for each beneficiary to avoid duplicates and ensure accurate identification.",
];

export const medicalCodesSteps = [
  "The first line of your Excel file should be <b>the column headers</b> as in the table example. Also make sure that <b>your column headers name are not changed</b> to avoid unnecessary problems.",
  "If the medical Code is already exist. The system will ignore the line.",
  "In the column Type Index, you must add the <b> Medical Code Type ID</b>, which are CPT(1), ICD-9 (2), ICD-10 (3). The Column 'Code Type' is used for your reference where you can add CPT or ICD-9 or ICD-10 ",
  "In the column Group, you must add the <b> Medical Code ID</b>, which you can get them by clicking here.",
  "In the column Active, you must add either <span style='font-weight:bold'>yes or no</span> ,small letter",
];
export const providerServicesSteps = [
  "The first line of your Excel file should be <b>the column headers</b> as in the table example. Also make sure that <b>your column headers name are not changed</b> to avoid unnecessary problems.",
  "If the provider service reference does not exist. The system will ignore the line.",
  "The intermediary step of connecting provider services to TPA services is mandatory.",
  "In order to group provider services, the service reference must be entered into the provider service group column.",
  "In the column Active, you must add either <span style='font-weight:bold'>yes or no</span> ,small letter",
];
export const PCIsSteps = [
  "The first line of your Excel file should be <b>the column headers</b> as in the table example. Also make sure that <b>your column headers name are not changed</b> to avoid unnecessary problems.",
  "If the Service is already exist. The system will ignore the line.",
];

export const ageGRoupSteps = [
  "You must download the sample and enter the data.",
  "The first line of your Excel file should be <b>the column headers</b> as in the table example. Also make sure <b>that your column headers name are not changed</b> to avoid unnecessary problems.",
  "If there is duplicate data in the age range (from or to age). The upload will fail.",
];

export const specialImportSteps = [
  "The first line of your Excel file should be <b>the column headers</b> as in the table example. Also make sure that <b>your column headers name are not changed</b> to avoid unnecessary problems.",
  "First Name is a mandotary column",
  "In the column<b> MiddleName, FamilyName, MaidenName  </b>, if no name put period or dot ( . ) ",
  " DOB column format:<b> dd -mm - yyyy</b>",
];
