import getChartColorsArray from "components/Common/ChartsDynamicColor"
import React from "react"
import ReactApexChart from "react-apexcharts"
import { Card, CardBody } from "reactstrap"

const ProviderChart = ({ dataColors, KPI }) => {
  // var barChartColor = getChartColorsArray(dataColors)

  const providers = KPI
    ? KPI.data?.map((item) => item?.healthcare_providers)
    : []

  const series = [
    {
      name: "",
      data:
        providers[0]?.map((value) => value.calculated_value).length > 0
          ? providers[0]
              ?.sort((a, b) => a.calculated_value - b.calculated_value)
              ?.map((value) => value.calculated_value)
          : [],
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["#00BDFF"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: providers[0]?.map((value) => value.first_name)
        ? providers[0]?.map((value) => value.first_name)
        : [],
    },
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title">{KPI?.name}</h4>
          <div>
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height="350"
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProviderChart
