import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Container } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { dashboardsID } from "utils/modulesIds";
import { convertNameToRoute } from "utils";
import { CompanyName } from "constants/TPA";
import { ModalSpinner } from "components/TP/Common/Forms";
import { flatten } from "lodash";

const Dashboard = (props) => {
  const history = useHistory();
  const { modules } = useSelector((state) => ({
    modules: state.Sidebar.modules,
  }));

  useEffect(() => {
    const firstDashboardName = modules?.find(
      (item) => item?.id === dashboardsID
    )?.sub_modules[0]?.name;

    const allSubModules = flatten(modules.map((module) => module?.sub_modules));
    const firstSubModule = allSubModules.length > 0 ? allSubModules[0] : null;

    if (modules.length > 0) {
      if (firstDashboardName) {
        const name = convertNameToRoute(firstDashboardName);
        history.push(`/${name}`);
      }
      if (firstSubModule) {
        const name = convertNameToRoute(firstSubModule?.name);
        history.push(`/${name}`);
      }
    } else return;
  }, [modules.length]);

  //meta title
  useEffect(() => {
    document.title = `${CompanyName} | Home Page`;
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex h-100 w-100 align-items-center justify-content-center">
            <ModalSpinner color="primary" />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
