import React from "react";
import { Skeleton } from "antd";

const TableSkeleton = ({ rowsNumber = 15 }) => {
  return (
    <Skeleton
      style={{ width: "100%" }}
      active
      title={false}
      paragraph={{
        rows: rowsNumber,
      }}
    />
  );
};

export default TableSkeleton;
