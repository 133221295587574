import React, { Fragment } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody } from "reactstrap";

const ClaimOverview = ({ KPI }) => {
  const months = KPI?.data?.map((month) => month.month_name);

  //claim overview
  const stackedColumnsoptions = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        endingShape: "rounded",
      },
    },

    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: months,
    },
    colors: ["#556ee6", "#F1B44C", "#34C38F"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `$ ${val}`;
        },
      },
    },
  };

  const benefitNames = new Set(
    KPI?.data?.flatMap((test) => test.benefits.map((benefit) => benefit.name))
  );
  const benefits = Array.from(benefitNames);

  const stackedColumnData = benefits.map((benefitName) => ({
    name: benefitName,
    data: KPI?.data?.map((monthBenefits) => {
      const Benefit = monthBenefits.benefits?.find(
        (monthBenefit) => monthBenefit.name === benefitName
      );
      return Benefit ? Benefit.calculated_value : 0;
    }),
  }));

  return (
    <Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title">{KPI?.name}</h4>
          <ReactApexChart
            options={stackedColumnsoptions}
            series={stackedColumnData}
            type="bar"
            height="419"
            className="apex-charts"
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default ClaimOverview;
