import React, { useEffect, useState } from "react"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer"
import ACESImage from "assets/images/ACES_COLORED.png"
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "col",
    backgroundColor: "#fff",
    width: "100%",
    height: "100%",
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    // fontFamily: "Oswald",
    color: "#4e4d4d",
  },
  text: {
    margin: 12,
    fontSize: 14,
    paddingBottom: 5,
    textAlign: "justify",
    // fontFamily: "Times-Roman",
  },
  topSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
    marginTop: 15,
    alignItems: "center",
  },
  image: {
    // marginVertical: 15,
    // marginTop: 15,
    width: 120,
    height: 70,
  },
  barCodeImage: {
    // marginLeft: "auto",
    width: 120,
    height: 70,
  },
  barCode: {
    alignContent: "right",
    marginRight: 10,
    marginBottom: 10,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
})

// Create Document Component
const PDFDocument = () => {
  const [barCode, setBarCode] = useState("")

  useEffect(() => {
    // For Barcode
    const canvas = document.createElement("canvas")
    JsBarcode(canvas, "10205478547", {
      // width: 1,
      // height: 20,
      // displayValue: true,
      // fontSize: 16,
    })
    setBarCode(canvas.toDataURL())
  }, [])
  return (
    <Document title="ACES | Medical Insurance">
      <Page size="A4" style={styles.page}>
        {/* <View style={styles.section}> */}
        <View style={styles.topSection}>
          <Image src={ACESImage} style={styles.image} />
          {barCode ? (
            <Image src={barCode} style={styles.barCodeImage} />
          ) : (
            <Text style={styles.barCode}>Bar Code</Text>
          )}
        </View>
        {/* </View> */}
        <Text style={styles.subtitle}>Subtitle #1</Text>
        <Text style={styles.text}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem,
          pariatur. Placeat, obcaecati reprehenderit corporis inventore
          aspernatur odio animi dolorem alias, voluptatibus, id sequi.
          Voluptatibus voluptatum dolor explicabo itaque adipisci provident?
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus impedit
          asperiores error, animi quidem a ipsam, harum, repudiandae molestiae
          ex in excepturi vel quasi odit distinctio ipsum! Reprehenderit, quis
          voluptates! Velit error beatae labore quasi rerum dolores cum maiores,
          quis atque culpa temporibus exercitationem saepe illo repellat
          laboriosam. Distinctio quia culpa iusto, odit explicabo saepe! Quo
          iure expedita at repellendus? Lorem ipsum dolor sit amet consectetur,
          adipisicing elit. Consequatur velit excepturi cum assumenda sequi
          facilis at repudiandae sapiente nobis. Soluta eum dolor facere in
          repellendus ratione doloribus nostrum enim dolore? Similique
          voluptatibus ut doloribus doloremque, hic nulla quo dolore, magni a
          repellendus error delectus quidem. Illo debitis quo aspernatur, maxime
          nisi veniam similique quidem, pariatur fuga mollitia, deserunt
          provident ipsa! Aperiam cum laboriosam tempore cupiditate fugiat
          quibusdam error asperiores, doloremque culpa numquam laborum, modi
          dolor quasi aliquid id, iusto hic totam quae officia. Ut at ducimus
          nihil? Voluptate, repudiandae consequuntur. Totam voluptatem, est
          pariatur sequi quos natus nemo! Facere, et repellendus maxime
          temporibus, fugiat animi iste quia dolorem reiciendis exercitationem
          optio in, neque similique. Perferendis modi commodi ipsam mollitia
          eius! Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Distinctio accusamus ipsum qui. Eveniet id nisi repellat consectetur,
          laborum ipsum pariatur consequatur quis delectus natus neque! Ipsum
          neque autem voluptate repellat. Placeat iure dolor fuga, quas nobis
          consectetur recusandae aut consequuntur incidunt est eos nulla
          voluptatibus. Recusandae harum, fugit voluptatibus eaque quaerat qui,
          accusamus eligendi sint deleniti autem repellat iure molestias. Neque,
          molestias pariatur blanditiis expedita veniam at itaque optio commodi
          perferendis reiciendis nesciunt quidem id tenetur harum inventore
          cumque quibusdam quos aperiam accusamus eum minima ut tempora aut
          porro! Nemo! Quo, excepturi, perspiciatis, rerum saepe eum cupiditate
          nobis fugiat minima quis ratione est mollitia ab! Odio consectetur
          commodi quas possimus vitae deserunt non incidunt! Ipsa, sequi eos!
          Fuga, facere perferendis. Eos amet omnis sequi libero dignissimos
          quas, iste soluta, veniam odit delectus, accusantium iusto id
          distinctio! Esse neque nemo saepe earum, rerum aliquam deleniti! Dicta
          nobis odio tempore non enim. Eaque a fugit magnam, minima at quo nobis
          aliquid. Nulla commodi velit vel officia ab harum maiores ea,
          exercitationem dignissimos unde eveniet, tempora necessitatibus
          voluptates animi similique? Sint, dicta aliquid? Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Magni sed iure eveniet fuga
          officiis quis facere exercitationem est error sit nesciunt aspernatur
          sapiente fugit, quas veniam voluptas! In, pariatur possimus.
        </Text>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  )
}

export default PDFDocument
