import { calculateBeneficaryLimits } from "helpers/Production/Policies/beneficaries_helper"
import React, { Fragment, useEffect, useState } from "react"
import { NotificationMessage, apiErrorrHandler, roundNumber } from "utils"

const RemiaingLimits = ({
  policyId,
  beneficaryId,
  benefitId,
  setBeneficiaryLimits,
  class_id,
  claim,
}) => {
  const [limits, setLimits] = useState([])

  const getBeneficiaryLimits = async () => {
    try {
      const res = await calculateBeneficaryLimits(beneficaryId, {
        policy_id: policyId,
        benefit_id: benefitId,
        class_id,
      })
      const limitsArray = res?.item?.limits
      setLimits(limitsArray?.filter(item => item?.benefit[0].id === benefitId))

      let addedLimit
      if (limitsArray.length > 0) {
        const modifiedLimit = limitsArray?.map(item => {
          const desiredLimit =
            item.value - (item.calculated_value + item.hold_amount)
          // limit < 0 leave it as it is
          if (desiredLimit < 0) {
            addedLimit = desiredLimit
          }
          // if IN claim or ER claim add approved estimation to limit
          if (
            (claim?.benefit?.slug === "IN" || claim?.benefit?.slug === "ER") &&
            desiredLimit > 0
          ) {
            addedLimit = desiredLimit + claim?.approved_estimation_amount
          }
          // if AMB claim or PR claim add payer total amount to limit
          if (
            (claim?.benefit?.slug === "AMB" || claim?.benefit?.slug === "PM") &&
            desiredLimit > 0
          ) {
            addedLimit = desiredLimit + claim?.payer_total_amount
          }
          if (!claim) {
            addedLimit = desiredLimit
          }
          return {
            id: item.id,
            benefit_id: item.benefit[0].id,
            limit: addedLimit,
          }
        })
        setBeneficiaryLimits(
          modifiedLimit.find(item => item.benefit_id === benefitId)
        )
        const limitItem = limitsArray?.filter(
          item => item?.benefit[0].id === benefitId
        )[0]
        setLimits([{ ...limitItem, limit: addedLimit }])
      } else {
        setBeneficiaryLimits(null)
        setLimits([])
      }
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("error", errorMessage)
    }
  }

  useEffect(() => {
    // if (class_id) {
    getBeneficiaryLimits()
    // }
  }, [class_id])

  return (
    <Fragment>
      {limits?.length > 0 ? (
        <Fragment>
          <span className="fw-bold text-uppercase text-white d-block mb-1 font-size-14">
            Remaining Limit
          </span>
          {limits.length > 0 &&
            limits.map((limit, index) => (
              <span key={limit.id} className="text-white font-size-12 fw-light">
                {limit?.benefit?.length > 0 && limit?.benefit[0]?.name}{" "}
                {/* {(limit.value - limit.calculated_value).toLocaleString("en-US")} */}
                {limit?.limit?.toLocaleString("en-US")}
                {limit?.type?.slug.includes("amount") ? "$" : ""}
                {index === limits.length - 1 ? "" : " | "}
              </span>
            ))}
        </Fragment>
      ) : null}
    </Fragment>
  )
}

export default RemiaingLimits
