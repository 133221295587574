import PropTypes from "prop-types";
import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

//Import Breadcrumb

//i18n
import { withTranslation } from "react-i18next";

import PageBreadCrumbAndTitle from "components/TP/Common/General/PageBreadCrumbAndTitle";
import LineApexChart from "components/TP/Charts/LineApexChart";
import LineColumnApexChart from "components/TP/Charts/LineColumnApexChart";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { dashboardsID, mainDashboardID } from "utils/modulesIds";
import ReactApexChart from "react-apexcharts";
import { claimPaths } from "common/data/routePaths";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { APIDateFormat } from "constants/TPA";
import dayjs from "dayjs";
import { CardUser } from "components/TP/dashboards";

const StaticDashboard = (props) => {
  const [dateRange, setDateRange] = useState({
    start_date: dayjs().add(-7, "d").format(APIDateFormat),
    end_date: dayjs().format(APIDateFormat),
  });

  const { module } = useSelector((state) => ({
    module: state.Sidebar?.modules
      ?.find((item) => item.id === dashboardsID)
      ?.sub_modules?.find((item) => item.id === mainDashboardID),
    loading: state.Sidebar?.loading,
  }));

  const reports = [
    {
      title: "Individual <br/> Loss Ratio",
      value: "110",
      badgeValue: "+ 1.2%",
      dir: "up",
      color: "yellowOrange",
      desc: "From Last Year",
    },
    {
      title: "Family <br/> Loss Ratio",
      value: "78",
      badgeValue: "+ 9%",
      dir: "down",
      color: "success",
      desc: "From Last Year",
    },
    {
      title: "Group <br/> Loss Ratio",
      value: "96",
      badgeValue: "+ 0.3%",
      color: "success",
      dir: "down",
      desc: "From Last Year",
    },
    {
      title: "Individual <br/> Retention Ratio",
      value: "20",
      badgeValue: "+ 0.3%",
      color: "success",
      dir: "up",
      desc: "From Last Year",
    },
    {
      title: "Family <br/> Retention Ratio",
      value: "80",
      badgeValue: "+ 0.3%",
      color: "success",
      dir: "up",
      desc: "From Last Year",
    },
    {
      title: "Group <br/> Retention Ratio",
      value: "96",
      badgeValue: "+ 2.3%",
      color: "yellowOrange",
      dir: "down",
      desc: "From Last Year",
    },
  ];

  //claim boxes
  const claimsCountData = [
    { label: "In-Patient", value: "1,650" },
    { label: "Pharmacy", value: 200 },
    { label: "Doctor Visits", value: 312 },
    { label: "Ambulatory", value: 800 },
  ];
  const claimsAmountData = [
    { label: "In-Patient", value: "$ 62,000" },
    { label: "Pharmacy", value: "$ 1,500" },
    { label: "Doctor Visits", value: "$ 2,600" },
    { label: "Ambulatory", value: "$ 20,000" },
  ];

  const ExpirePoliciesData = [4, 9, 16];
  const ActivePoliciesData = [4723, 820, 1690];

  const options = {
    chart: {
      width: "100%",
      height: "400px",
      type: "pie",
    },
    labels: ["Group", "Individual", "Family"],
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 150,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },

    colors: ["#556ee6", "#f1b44c", "#f46a6a", "#50a5f1", "#34c38f"],
  };

  //Top Diseases
  const diseasesdata = [
    {
      name: "",
      data: [
        5000, 8000, 12000, 14000, 15000, 16000, 19000, 21000, 23000, 25000,
      ],
    },
  ];
  const diseasesOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["rgba(52, 195, 143, 0.85)"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: [
        "Blood & blood-forming organ diseases",
        "Hypertensive disease ",
        "Persons Encountering Health Services",
        "Thyroid gland disease",
        "Rheumatism, excluding back",
        "Other female genital tract disorders",
        "Breast disorders",
        "Dorsopathies",
        "Arthropathies & related disorders ",
        "Symptoms",
      ],
    },
  };

  //Top providers
  const ProvidersData = [
    {
      name: "",
      data: [
        28.208, 32.638, 33.1, 52.002, 61.0, 62.429, 67.748, 74.789, 80.236,
        91.051,
      ],
    },
  ];
  const ProvidersOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["#00BDFF"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: [
        "Clinique Du Levant",
        "Lebanese Healthcare Management ... ",
        "LAUMC-St. John Hospital",
        "Centre Hosp CHU-Notre Dame...",
        "Hopital Libanais Geitaoui",
        "Rizk Hospit Hopital St. Joseph",
        "University Medica University Medical Center",
        "St. George Hospital ",
        "Bellevue Medical Center ",
        "Hotel-Dieu De France",
      ],
    },
  };

  //claim overview
  const stackedColumnsoptions = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        endingShape: "rounded",
      },
    },

    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
    },
    colors: ["#556ee6", "#F1B44C", "#34C38F"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  };
  const stackedColumnData = [
    {
      name: "PM",
      data: [5000, 15000, 4000, 24000, 7500, 12000, 11000, 8000, 27000, 5000],
    },
    {
      name: "AMB",
      data: [5000, 10000, 7500, 2000, 5000, 10000, 7000, 10000, 5000, 5000],
    },
    {
      name: "IN",
      data: [8000, 15000, 7000, 6500, 12000, 22000, 6000, 7000, 8000, 5000],
    },
  ];

  const claimPerformanceseries = [76, 67, 61];
  const claimPerformanceOptions = {
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: "35%",
          background: "transparent",
          image: void 0,
        },
        track: {
          show: !0,
          startAngle: void 0,
          endAngle: void 0,
          background: "#f2f2f2",
          strokeWidth: "95%",
          opacity: 1,
          margin: 12,
          dropShadow: {
            enabled: !1,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
        dataLabels: {
          name: {
            show: !0,
            fontSize: "16px",
            fontWeight: 600,
            offsetY: -10,
          },
          value: {
            show: !0,
            fontSize: "14px",
            offsetY: 4,
            formatter: function (e) {
              return e + "%";
            },
          },
          total: {
            show: !0,
            label: "Total",
            color: "#FFDB58",
            fontSize: "16px",
            fontFamily: void 0,
            fontWeight: 600,
            formatter: function (e) {
              // return (
              //   e.globals.seriesTotals.reduce(function (e, t) {
              //     return e + t
              //   }, 0) + "%"
              // )
              return "100%";
            },
          },
        },
      },
    },
    stroke: {
      lineCap: "round",
    },
    colors: ["#6c82e9", "#f1bd65", "#50a5f1d9"],
    labels: ["AMB", "IN", "PM"],
    legend: { show: !1 },
  };

  const funnelSeries = [
    {
      // name: "funnel",
      data: [
        {
          x: "RB01",
          y: 1380,
        },
        {
          x: "KR12",
          y: 1100,
        },
        {
          x: "AA05",
          y: 990,
        },
        {
          x: "SM02",
          y: 880,
        },
        {
          x: "KS03",
          y: 740,
        },
        {
          x: "MN06",
          y: 548,
        },
        {
          x: "DS08",
          y: 330,
        },
        {
          x: "SS05",
          y: 200,
        },
      ],
    },
  ];

  const funnelOptions = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
        barHeight: "80%",
        isFunnel: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
      },
      dropShadow: {
        enabled: true,
      },
    },
    title: {
      text: "",
      align: "middle",
    },
    xaxis: {
      categories: [],
    },
    legend: {
      show: false,
    },
    colors: ["#556ee6"],
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  const candlesticData = {
    grid: {
      zlevel: 0,
      x: 50,
      x2: 50,
      y: 30,
      y2: 30,
      borderWidth: 0,
      backgroundColor: "rgba(0,0,0,0)",
      borderColor: "rgba(0,0,0,0)",
    },
    color: ["#6c82e9", "#f1bd65", "#50a5f1d9"],
    xAxis: {
      data: ["Beirut", "Mount Lebanon", "The North", "The South"],
      axisLine: {
        lineStyle: {
          color: "#8791af",
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(166, 176, 207, 0.1)",
        },
      },
    },
    yAxis: {
      axisLine: {
        lineStyle: {
          color: "#8791af",
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(166, 176, 207, 0.1)",
        },
      },
    },
    series: [
      {
        type: "k",
        data: [
          [20, 30, 10, 35],
          [40, 35, 30, 55],
          [33, 38, 33, 40],
          [40, 40, 32, 42],
        ],

        itemStyle: {
          normal: {
            color: "#556ee6",
            color0: "#34c38f",
            borderColor: "#556ee6",
            borderColor0: "#34c38f",
          },
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <PageBreadCrumbAndTitle pageType="ddd" title={module?.title} />
          {/* Rounded Corners White Card and Table inside it */}

          <Row>
            {/* Welcome User Card */}
            <CardUser setDateRange={setDateRange} dateRange={dateRange} />
          </Row>

          <Row>
            {reports.map((report) => (
              <Col md={2} key={report.title}>
                <Card>
                  <CardBody>
                    <div className="d-flex align-items-center mb-3">
                      <h6>{parse(report.title)}</h6>
                    </div>
                    <div className="text-muted mt-3">
                      <h4>
                        {report.value}%
                        <i
                          className={
                            "mdi mdi-chevron-" +
                            report.dir +
                            " ms-1 text-" +
                            report.color
                          }
                        />
                      </h4>
                      <div className="d-flex">
                        <span
                          className={
                            "badge badge-soft-" + report.color + " font-size-12"
                          }
                        >
                          {" "}
                          {report.badgeValue}{" "}
                        </span>{" "}
                        <span className="ms-2 text-truncate">
                          {report.desc}
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <div className="mb-4 card-title">Members Overview</div>
                  <LineApexChart dataColors='["--bs-blue", "--bs-green"]' />
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <div className="card-title mb-4">Business Overview </div>
                  <LineColumnApexChart dataColors='["--bs-danger","--bs-blue", "--bs-green"]' />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {/* <div className="row"> */}
            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <div className="float-end">
                    <select className="form-select form-select-sm ms-2">
                      <option value="march">August 2024</option>
                      <option value="feb">February 2024</option>
                      <option value="jan">January 2024</option>
                      <option value="dec">December 2024</option>
                    </select>
                  </div>
                  <h4 className="card-title mb-3 ">Claim Performance</h4>
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="mt-4 ml-3" style={{ marginLeft: "15px" }}>
                        <p>Total Claimed</p>
                        <h4>$ 248,675</h4>
                        <p className="text-muted mb-4">
                          + 18,400 (8.2%){" "}
                          <i className="mdi mdi-arrow-up ms-1 text-success"></i>
                        </p>
                        <div className="row">
                          <div className="col-12">
                            <div>
                              <p className="mb-2">New Population</p>
                              <h5>$ 14,318</h5>
                            </div>
                          </div>
                          <div className="col-12">
                            <div>
                              <p className="mb-2">Medical Trend</p>
                              <h5>$ 4,082</h5>
                            </div>
                          </div>
                        </div>
                        {/* <div className="mt-4">
                          <a className="btn btn-primary btn-sm">
                            View more{" "}
                            <i className="mdi mdi-arrow-right ms-1"></i>
                          </a>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-5">
                      <div>
                        <div id="wallet-balance-chart">
                          <ReactApexChart
                            options={claimPerformanceOptions}
                            series={claimPerformanceseries}
                            type="radialBar"
                            height={300}
                            width={260}
                            className="apex-charts"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="align-self-center col-sm-6 col-lg-4">
                      <div>
                        <p className="mb-2">
                          <i
                            className="mdi mdi-circle align-middle font-size-10 me-2"
                            style={{ color: "#f1bd65" }}
                          ></i>{" "}
                          In Patient
                        </p>
                        <h5>
                          239 Claims:{" "}
                          <span className="text-muted font-size-14">
                            $ 198,310
                          </span>
                        </h5>
                        <p className="text-muted mb-4">
                          + 6,400 (7.4%){" "}
                          <i className="mdi mdi-arrow-up ms-1 text-success"></i>
                        </p>
                      </div>
                      <div className="mt-4 pt-2">
                        <p className="mb-2">
                          <i
                            className="mdi mdi-circle align-middle font-size-10 me-2"
                            style={{ color: "#6c82e9" }}
                          ></i>{" "}
                          Ambulatory
                        </p>
                        <h5>
                          550 Claims:{" "}
                          <span className="text-muted font-size-14">
                            $ 39,600
                          </span>
                        </h5>
                        <p className="text-muted mb-4">
                          + 10,000 (32.86%){" "}
                          <i className="mdi mdi-arrow-up ms-1 text-success"></i>
                        </p>
                      </div>
                      <div className="mt-4 pt-2">
                        <p className="mb-2">
                          <i
                            className="mdi mdi-circle align-middle font-size-10 me-2"
                            style={{ color: "#50a5f1d9" }}
                          ></i>{" "}
                          Prescription Medicine
                        </p>
                        <h5>
                          71 Claims:{" "}
                          <span className="text-muted font-size-14">
                            $ 10,765
                          </span>
                        </h5>

                        <p className="text-muted mb-4">
                          + 2,000 (24.36%){" "}
                          <i className="mdi mdi-arrow-up ms-1 text-success"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}

            <Col md={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title">Claim Overview</h4>
                  <ReactApexChart
                    options={stackedColumnsoptions}
                    series={stackedColumnData}
                    type="bar"
                    height="300"
                    className="apex-charts"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">
                    Top 10 Brokers By Loss Ratio
                  </h4>
                  <div className="mb-5">
                    <h4 className="card-title mb-0 text-center">
                      Loss Ratio %
                    </h4>
                    <ReactApexChart
                      type="bar"
                      height="250"
                      width="100%"
                      options={funnelOptions}
                      series={funnelSeries}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title">Active Policies</h4>
                  <div className="mt-2">
                    <ReactApexChart
                      className="d-flex justify-content-center align-items-center"
                      type="pie"
                      height="380px"
                      width="350px"
                      options={options}
                      series={ActivePoliciesData}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* <Col md={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title">Candle</h4>
                  <div className="mt-2">
                    <ReactEcharts
                      style={{ height: "350px" }}
                      option={candlesticData}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title">Top Providers</h4>
                  <div>
                    <ReactApexChart
                      options={ProvidersOptions}
                      series={ProvidersData}
                      type="bar"
                      height="350"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title ">Top Diseases</h4>
                  <div>
                    <ReactApexChart
                      options={diseasesOptions}
                      series={diseasesdata}
                      type="bar"
                      height="350"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="mb-4 h4 card-title">Jumbo Claims</div>
                  <div className="mb-2 row" />
                  <div className="table-responsive">
                    <table className="align-middle table-nowrap mb-0 table table-hover">
                      <thead className="table-light">
                        <tr>
                          <th colSpan={1} className="sorting sorting_desc">
                            <div className="cursor-pointer select-none">
                              Policy Number
                            </div>
                          </th>
                          <th colSpan={1} className="sorting sorting_desc">
                            <div className="cursor-pointer select-none">
                              Policy Name
                            </div>
                          </th>
                          <th colSpan={1} className="sorting sorting_desc">
                            <div className="cursor-pointer select-none">
                              Broker Name
                            </div>
                          </th>
                          <th colSpan={1} className="sorting sorting_desc">
                            <div className="cursor-pointer select-none">
                              Claim Amount
                            </div>
                          </th>
                          <th colSpan={1} className="sorting sorting_desc">
                            <div className="cursor-pointer select-none">
                              Claim Date
                            </div>
                          </th>
                          <th colSpan={1} className="sorting sorting_desc">
                            <div className="cursor-pointer select-none">
                              Provider Name
                            </div>
                          </th>
                          <th colSpan={1} className="sorting sorting_desc">
                            <div className="cursor-pointer select-none">
                              View Details
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <a className="text-body fw-bold" href="/dashboard">
                              #SK2545
                            </a>
                          </td>
                          <td>IPT</td>
                          <td>
                            <span> Rima Karim Khoury</span>
                          </td>
                          <td>$39,200</td>
                          <td>2024-10-18</td>
                          <td>
                            <span className="font-size-11 badge-soft-danger badge bg-secondary">
                              AUBMC
                            </span>
                          </td>
                          <td>
                            <Link
                              target="_blank"
                              to={`${claimPaths.claimForm}/view/3030`}
                              className="btn-sm btn-rounded btn btn-primary"
                            >
                              View Claim
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a className="text-body fw-bold" href="/dashboard">
                              #SK2545
                            </a>
                          </td>
                          <td>Gefinor Rotana</td>
                          <td>
                            <span> Elias Farid Mansour</span>
                          </td>
                          <td>$16,000</td>
                          <td>2024-10-16</td>
                          <td>
                            <span className="font-size-11 badge-soft-danger badge bg-secondary">
                              HDF
                            </span>
                          </td>
                          <td>
                            <Link
                              target="_blank"
                              to={`${claimPaths.claimForm}/view/3031`}
                              className="btn-sm btn-rounded btn btn-primary"
                            >
                              View Claim
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a className="text-body fw-bold" href="/dashboard">
                              #SK2545
                            </a>
                          </td>
                          <td>BMA paints</td>
                          <td>
                            <span> Nour Samir Abi Nader</span>
                          </td>
                          <td>$12,359</td>
                          <td>2024-10-15</td>
                          <td>
                            <span className="font-size-11 badge-soft-danger badge bg-secondary">
                              Mount Lebanon
                            </span>
                          </td>
                          <td>
                            <Link
                              target="_blank"
                              to={`${claimPaths.claimForm}/view/3046`}
                              className="btn-sm btn-rounded btn btn-primary"
                            >
                              View Claim
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

StaticDashboard.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(StaticDashboard);
