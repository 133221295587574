import PropTypes from "prop-types";
import React from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions";

//i18n
import { withTranslation } from "react-i18next";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";

const ForgetPasswordPage = (props) => {
  //meta title
  document.title = "Forget Password";

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
      redirect_link:
        process.env.NODE_ENV === "production"
          ? `http://front-${
              process.env.REACT_APP_TARGET_URL.split("/")[2]
            }/new-password`
          : `http://localhost:${process.env.PORT || 3000}/new-password`,
    },

    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
    }),
    onSubmit: (values) => {
      dispatch(userForgetPassword(values, props.history));
      props.history.push("/confirm-mail");
    },
  });

  const {
    errors,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange,
    isSubmitting,
    handleSubmit,
  } = validation;

  const { forgetError, forgetSuccessMsg, loading } = useSelector((state) => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
    loading: state.ForgetPassword.loading,
  }));

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        {/* Link To Home Page */}

        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>

                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  {/* Error and Success Messages */}

                  <div className="p-2">
                    {/* Error Message */}

                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}

                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    {!forgetError && !forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        Enter your Email and instructions will be sent to you!
                      </Alert>
                    ) : null}

                    {/* Form */}
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Username</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Enter username"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.username || ""}
                          invalid={
                            touched.username && errors.username ? true : false
                          }
                        />
                        {touched.username && errors.username ? (
                          <FormFeedback type="invalid">
                            {errors.username}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            disabled={loading}
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            {props.t("Reset")}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {props.t("Go back to")}{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    {props.t("Login")}
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(withTranslation()(ForgetPasswordPage));
