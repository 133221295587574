import { buttonsTitles, claimStatus } from "common/data"
import { claimPaths } from "common/data/routePaths"
import { TableSkeleton } from "components/Common"
import { SearchSelect } from "components/TP/Common/Forms"
import {
  NoRecords,
  SingleRowAction,
  StickyHeaderTable,
} from "components/TP/Common/Tables"
import { getAllClaims } from "helpers/Claims/ClaimForm"
import { unionBy } from "lodash"
import { isEqual } from "lodash"
import { union } from "lodash"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { Col, Row } from "reactstrap"
// Formik validation

import {
  NotificationMessage,
  apiErrorrHandler,
  fieldFinder,
  isInputInvalid,
} from "utils"

const InnerClaimsTable = ({
  validation,
  moduleName,
  oldClaims,
  bactchClaimGroup,
  sum,
  setSum,
  t,
}) => {
  // descturcture validation object
  const { values, setFieldValue } = validation
  // Batch Claims Permission Level 3 states
  const {
    fields: inputs,
    name,
    loading,
  } = useSelector((state) => ({
    fields: state.Module.fields,
    name: state.Module.name,
    loading: state.Module.loading,
  }))

  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [claimForms, setClaimForms] = useState(oldClaims ? oldClaims : [])

  const location = useLocation()
  // if it has a value (this means we are in update page)
  const recordId = location.pathname.split("/")[3]

  // const [urlQuery, setUrlQuery] = useState(null)

  const [columns, setColumns] = useState([])
  const getClaimFroms = async ({
    page,
    per_page,
    sort_by,
    sort_type,
    search_value,
    search_keys,
    search_values,
    claim_ids,
  }) => {
    // const uniqueIDs = union(
    //   claimForms.map(item => item.id),
    //   claim_ids
    // )

    if (
      isEqual(
        claim_ids,
        claimForms.map((item) => item.id)
      )
    ) {
      NotificationMessage("Error", "The Data is Already here")
      return
    }
    try {
      setIsLoading(true)
      const res = await getAllClaims({
        page: 1,
        per_page: 100,
        sort_by,
        sort_type,
        search_value,
        search_keys,
        search_values,
        claim_ids: claim_ids,
      })

      // setClaimForms(unionBy(claimForms, res.items, "id"))
      setClaimForms(res.items)
      setPagination({
        size: res.size,
        page: res.page,
        per_page: res.per_page,
        total_pages: res.total_pages,
        total: res.total_size,
      })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  useEffect(() => {
    if (moduleName === "batchClaim" && inputs.length > 0) {
      setColumns([
        "#",
        {
          id: 435,
          label: fieldFinder(inputs, 435)?.label_name,
        },
        {
          id: 436,
          label: fieldFinder(inputs, 436)?.label_name,
        },
        // {
        //   id: 437,
        //   label: fieldFinder(inputs, 437)?.label_name,
        // },
        {
          id: 438,
          label: fieldFinder(inputs, 438)?.label_name,
        },
        {
          id: 439,
          label: fieldFinder(inputs, 439)?.label_name,
        },
        {
          id: 440,
          label: fieldFinder(inputs, 440)?.label_name,
        },
        // {
        //   id: 441,
        //   label: fieldFinder(inputs, 441)?.label_name,
        // },
        {
          id: 442,
          label: fieldFinder(inputs, 442)?.label_name,
        },
        {
          id: 443,
          label: fieldFinder(inputs, 443)?.label_name,
        },
        {
          id: 996,
          label: fieldFinder(inputs, 996)?.label_name,
        },
        buttonsTitles.actions,
      ])
    }

    if (moduleName === "paymentOrder" && inputs.length > 0) {
      setColumns([
        "#",
        {
          id: 475,
          label: fieldFinder(inputs, 475)?.label_name,
        },
        {
          id: 476,
          label: fieldFinder(inputs, 476)?.label_name,
        },
        {
          id: 477,
          label: fieldFinder(inputs, 477)?.label_name,
        },
        {
          id: 478,
          label: fieldFinder(inputs, 478)?.label_name,
        },
        {
          id: 479,
          label: fieldFinder(inputs, 479)?.label_name,
        },
        {
          id: 480,
          label: fieldFinder(inputs, 480)?.label_name,
        },
        {
          id: 481,
          label: fieldFinder(inputs, 481)?.label_name,
        },
        {
          id: 482,
          label: fieldFinder(inputs, 482)?.label_name,
        },
        {
          id: 483,
          label: fieldFinder(inputs, 483)?.label_name,
        },
        buttonsTitles.actions,
      ])
    }
  }, [inputs, moduleName])

  const getDataOfIndexName = (obj, fieldId) => {
    let data
    const commingIndexName = fieldFinder(inputs, fieldId).index_name
    const nameHasDots = commingIndexName.includes(".") ? true : false
    if (nameHasDots) {
      const splitted = commingIndexName.split(".")
      if (splitted.length > 1) {
        data = obj
          ? obj[splitted[0]]
            ? obj[splitted[0]][splitted[1]]
            : null
          : null
      } else {
        data = obj ? obj[splitted[0]] : null
      }
    } else {
      data = obj ? obj[commingIndexName] : null
    }

    return data
  }

  //calculate total claimed
  useEffect(() => {
    const totalSum = claimForms.reduce((num, claimForm) => {
      return num + (claimForm.total_claimed_amount || 0)
    }, 0)

    if (totalSum !== sum) {
      setSum(totalSum)
    }
  }, [claimForms, sum])
  return (
    <div>
      <Row>
        {/* <Col md={11}>
          <SearchTree
            id="54"
            name="claims"
            placeholder="Please Select"
            noLabel={true}
            value={values.claims || []}
            onChange={setFieldValue}
            onBlur={validation.setFieldTouched}
            // treeData={regionTree}
            fieldNames={{
              children: "children",
              label: "reference",
              value: "id",
            }}
            getTreeData={urlQuery ? getAllClaims : () => {}}
            dynamicUrlQuery={urlQuery && urlQuery}
            invalid={isInputInvalid(validation, "claims")}
            error={validation.errors.claims}
            multiple={true}
            wrapperClassNames="mb-3"
          />
        </Col> */}

        <Col md={12}>
          <SearchSelect
            id="54"
            name="claims"
            placeholder="Please Select"
            // noLabel={true}
            label="Claims"
            value={values.claims}
            onChange={setFieldValue}
            onBlur={validation.setFieldTouched}
            mappingValue="id"
            // mappingLabel="reference"
            generateCustomLabels={(item) =>
              `${item.bar_code} | ${item.reference} | ${item.beneficiary?.name}`
            }
            // getOptionsData={urlQuery ? getAllClaims : () => {}}
            getOptionsData={
              values.healthcare_provider_id ? getAllClaims : () => {}
            }
            dynamicQuery={
              moduleName === "paymentOrder"
                ? {
                    benefit_id: values.benefit_id,
                    healthcare_provider_ids: values.healthcare_provider_id,
                  }
                : {
                    healthcare_provider_ids: [values.healthcare_provider_id],
                    payer_id: values.payer_id,
                  }
            }
            // dynamicQuery={
            //   values.healthcare_provider_id  && {
            //     healthcare_provider_id:
            //       values.healthcare_provider_id,
            //   }
            // }
            // load only approved claim // in batch claim forms and
            // load only processed claim // in Payment Orders forms and
            urlAddOns={
              moduleName === "batchClaim"
                ? { status_ids: [claimStatus.returned, claimStatus.approved] }
                : moduleName === "paymentOrder"
                ? { status_id: claimStatus.processed }
                : null
            }
            // dynamicQuery={urlQuery && urlQuery}
            invalid={isInputInvalid(validation, "claims")}
            error={validation.errors.claims}
            multiple={true}
            hideSelectedOptions={false}
            wrapperClassNames="mb-3"
            onSelectData={(data) => {
              console.log("data", data)
              if (data.length > 0) {
                const combinedClaimIds =
                  oldClaims && oldClaims?.length > 0
                    ? [
                        ...data.map((item) => item.id),
                        ...oldClaims?.map((item) => item.id),
                      ]
                    : [...data.map((item) => item.id)]
                getClaimFroms({
                  claim_ids: combinedClaimIds,
                })
                setFieldValue(
                  "claims",
                  oldClaims && oldClaims?.length > 0
                    ? [
                        ...data.map((item) => item.id),
                        ...oldClaims?.map((item) => item.id),
                      ]
                    : [...data.map((item) => item.id)]
                )
              } else {
                setClaimForms(
                  oldClaims && oldClaims.length > 0 ? oldClaims : []
                )
                setFieldValue(
                  "claims",
                  oldClaims && oldClaims?.length > 0
                    ? oldClaims.map((item) => item.id)
                    : []
                )
              }
            }}
            selectAll={true}
          />
        </Col>
        {/* 
        <Col md={1}>
          <button
            className="btn btn-secondary"
            disabled={
              validation.isSubmitting ||
              isLoading ||
              values.claims.length <= 0
            }
            type="button"
            onClick={() =>
              getClaimFroms({ claim_ids: values.claims })
            }
          >
            Add
          </button>
        </Col> */}
      </Row>

      {/* Table */}

      <StickyHeaderTable
        columns={columns}
        isLoading={isLoading || loading ? true : false}
        noData={claimForms?.length === 0 ? true : false}
      >
        {claimForms.length > 0 &&
          claimForms.map((claimForm, index) => (
            <tr key={claimForm.id}>
              <td>{index + 1}</td>
              {/* service date */}
              <td>
                {getDataOfIndexName(
                  claimForm,
                  moduleName === "batchClaim" ? 435 : 475
                )}
                {/* {claimForm.admission_date} */}
              </td>
              {/* reference */}
              <td>
                {/* {getDataOfIndexName(
                        claimForm,
                        moduleName === "batchClaim" ? 436 : 476
                      )} */}
                {claimForm?.reference}
              </td>
              {/* invoice */}
              {/* <td>
                {getDataOfIndexName(
                  claimForm,
                  moduleName === "batchClaim" ? 437 : 477
                )} */}
              {/* {claimForm.provider_reference} */}
              {/* </td> */}
              {/* card Number */}
              <td>
                {/* {claimForm?.beneficiary?.code} */}
                {getDataOfIndexName(
                  claimForm,
                  moduleName === "batchClaim" ? 438 : 478
                )}
              </td>
              {/* Patient name */}
              <td>
                {/* {claimForm?.beneficiary?.name} */}
                {getDataOfIndexName(
                  claimForm,
                  moduleName === "batchClaim" ? 439 : 479
                )}
              </td>
              {/* FOB */}
              <td>
                {getDataOfIndexName(
                  claimForm,
                  moduleName === "batchClaim" ? 440 : 480
                )}
              </td>
              {/* payer name */}
              {/* <td> */}
              {/* {getDataOfIndexName(
                        claimForm?.beneficiary?.policy,
                        moduleName === "batchClaim" ? 441 : 481
                      )} */}
              {/* {claimForm?.beneficiary?.policy?.payer?.first_name} */}
              {/* </td> */}
              {/* total claimed */}
              <td>
                {getDataOfIndexName(
                  claimForm,
                  moduleName === "batchClaim" ? 442 : 482
                )?.toLocaleString("en-US")}
              </td>
              {/* currency */}
              <td>
                {claimForm?.currency?.name}
                {/* {getDataOfIndexName(
                  claimForm?.beneficiary?.policy,
                  moduleName === "batchClaim" ? 443 : 483
                )} */}
              </td>
              <td>{claimForm?.status?.name}</td>
              {moduleName === "batchClaim" && recordId ? (
                <td>
                  {/* in batch claim (claim tables hide the delete button if the claim is not received) */}
                  <div className="d-flex align-items-center gap-1">
                    {claimForm.status &&
                      (claimForm.status.slug === "claimStatus_received" ||
                        claimForm.status.slug === "claimStatus_approved" ||
                        claimForm.status.slug === "claimStatus_returned") && (
                        <SingleRowAction
                          color="text-danger"
                          onClick={() => {
                            const filteredClaims = claimForms.filter(
                              (item) => item.id !== claimForm.id
                            )
                            setClaimForms(filteredClaims)
                            setFieldValue(
                              "claims",
                              filteredClaims.map((item) => item.id)
                            )
                          }}
                          actionName="Delete"
                          iconClass="mdi mdi-close"
                        />
                      )}
                    {claimForm.status &&
                      claimForm.status.slug === "claimStatus_received" && (
                        <SingleRowAction
                          to={`${claimPaths.claimForm}/processing/${claimForm?.id}`}
                          target="_blank"
                          iconClass="mdi mdi-calculator"
                          actionName={buttonsTitles.viewProcessing}
                          record={claimForm}
                        />
                      )}
                  </div>
                </td>
              ) : (
                ""
              )}
              {!recordId && (
                <td>
                  <SingleRowAction
                    color="text-danger"
                    onClick={() => {
                      const filteredClaims = claimForms.filter(
                        (item) => item.id !== claimForm.id
                      )
                      setClaimForms(filteredClaims)
                      setFieldValue(
                        "claims",
                        filteredClaims.map((item) => item.id)
                      )
                    }}
                    actionName="Delete"
                    iconClass="mdi mdi-close"
                  />
                </td>
              )}
            </tr>
          ))}
      </StickyHeaderTable>
    </div>
  )
}

export default withTranslation()(InnerClaimsTable)
