import PropTypes from "prop-types"
import React, { Fragment, useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import { PDFViewer } from "@react-pdf/renderer"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import PDFDocument from "./PDFDocument"
import ClaimPDFReport from "./ClaimPDFReport"
import { showClaim } from "helpers/Claims/ClaimForm"
import {
  NotificationMessage,
  apiErrorrHandler,
  convertNullToString,
} from "utils"
import Barcode from "react-barcode"
import moment from "moment"

// redux

const PDFViewerPage = props => {
  //meta title
  document.title = "ACES | PDF Viewer"

  const [generateReport, setGenerateReport] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [claim, setClaim] = useState(null)
  const [error, setError] = useState("")

  const getClaim = async id => {
    setIsLoading(true)
    try {
      const response = await showClaim(id)
      setClaim(convertNullToString(response.item))
      setError("")
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setIsLoading(false)
      setGenerateReport(false)
    }
  }

  useEffect(() => {
    if (generateReport) {
      getClaim(3)
    } else {
      return
    }
  }, [generateReport])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="PDF Viewer" breadcrumbItem="PDF Viewer" />

          {/* Rounded Corners White Card and Table inside it */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PDFViewer
                    showToolbar={false}
                    style={{ width: "100%", height: "100vh" }}
                  >
                    <PDFDocument />
                  </PDFViewer>
                  {/* {claim ? (
                    <PDFViewer
                      showToolbar={false}
                      style={{ width: "100%", height: "100vh" }}
                    >
                      <ClaimPDFReport claim={claim} />
                    </PDFViewer>
                  ) : (
                    <Button
                      color="primary"
                      onClick={() => setGenerateReport(true)}
                    >
                      {isLoading ? (
                        <Fragment>
                          Generating PDF
                          <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                        </Fragment>
                      ) : (
                        <Fragment>
                          Generate Report
                          <i className="mdi mdi-cloud-print-outline align-middle font-size-16 ms-2"></i>
                        </Fragment>
                      )}
                    </Button>
                  )} */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

PDFViewerPage.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(PDFViewerPage)
