import PropTypes from "prop-types";
import React, { Fragment, useMemo, useState } from "react";
import { Modal } from "reactstrap";

// i18n
import { withTranslation } from "react-i18next";
import { TableContainer } from "components/Common";
import moment from "moment";
import { findDateDifference } from "utils";
import { buttonsTitles } from "common/data";

const StatusHistoryModal = ({ show, onCloseClick, loading, statuses, t }) => {
  const columns = useMemo(
    () => [
      // {
      //   Header: "Id",
      //   accessor: "id",
      // },
      {
        Header: "Status",
        accessor: "status.name",
      },
      {
        Header: "User",
        accessor: "created_by.name",
      },
      {
        Header: "Reason",
        accessor: "reason.name",
      },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: (cellProps) => {
          const row = cellProps.row.original;
          return (
            <Fragment>{moment(row?.created_at).format("DD-MM-YYYY")}</Fragment>
          );
        },
      },
      {
        Header: "Time",
        accessor: "time",
        Cell: (cellProps) => {
          const row = cellProps.row.original;
          return (
            <Fragment>{moment(row?.created_at).format("hh:mm:ss")}</Fragment>
          );
        },
      },
      {
        Header: "Difference",
        accessor: "difference",

        Cell: (cellProps) => {
          const row = cellProps.row.original;
          console.log(`row ${row.id}`, row?.created_at);
          return <Fragment>{findDateDifference(row?.created_at)}</Fragment>;
        },
      },
    ],
    []
  );

  return (
    <Modal
      size="xl"
      isOpen={show}
      toggle={onCloseClick}
      backdrop="static"
      className="modal-fullscreen"
    >
      <div className="modal-header">
        {/* Modal Header Title */}
        <h5 className="modal-title mt-0" id="myModalLabel">
          Status History
        </h5>

        {/* close button on the top right section */}
        <button
          type="button"
          onClick={onCloseClick}
          className="btn-close position-absolute end-0 top-0 m-3"
        ></button>
      </div>

      {/* Modal Body */}
      <div className="modal-body">
        <TableContainer
          removeActionsColumns={true}
          removeActionsButtons={true}
          columns={columns}
          data={statuses.sort((a, b) => b.id - a.id)}
          handleAdd={() => {}}
          pagination={null}
          hidePagination={true}
          getData={() => {}}
          loading={loading}
          basicTable={true}
        />
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={onCloseClick}
        >
          {buttonsTitles.close}
        </button>
      </div>
    </Modal>
  );
};

StatusHistoryModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
  isSaving: PropTypes.bool,
  children: PropTypes.any,
  // modalTitle: PropTypes.string,
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default withTranslation()(StatusHistoryModal);
