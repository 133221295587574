import moment from "moment"
import React, { useState } from "react"
import { useLocation } from "react-router-dom"
import { CardBody } from "reactstrap"
import NetworkContractsModal from "./NetworkContractsModal"
import MultiSkeleton from "../Common/MultiSkeleton"

const InsuranceDetailsCard = ({ beneficiary, isLoading }) => {
  const [isNetworkModal, setIsNetwrokModal] = useState(false)
  const [networkId, setNetworkId] = useState(null)

  return (
    <aside className="card" id="sticky-sidebox">
      <CardBody className="px-1">
        {isLoading ? (
          <MultiSkeleton length={10} />
        ) : (
          <div className="table-responsive">
            <table className="table">
              <tbody>
                <tr className="d-flex flex-column">
                  <th scope="row" className="border-0">
                    Reference:
                  </th>
                  <td className="text-break">
                    <span>{beneficiary?.code}</span>
                    <hr />
                    {beneficiary?.policy_reference
                      ? beneficiary?.policy_reference
                      : "N/A"}
                  </td>
                </tr>
                <tr className="d-flex flex-column">
                  <th scope="row" className="border-0">
                    DOB:
                  </th>
                  {beneficiary && beneficiary.birth_date ? (
                    <td>
                      {beneficiary?.birth_date}
                      {` (${moment().diff(beneficiary.birth_date, "years")} Y)`}
                    </td>
                  ) : (
                    <td>N/A</td>
                  )}
                </tr>

                <tr className="d-flex flex-column">
                  <th scope="row" className="border-0">
                    Validation:
                  </th>
                  <td>
                    {beneficiary?.policy?.start_date
                      ? beneficiary?.policy?.start_date
                      : "N/A"}
                    <b> To</b> <br />
                    {beneficiary?.policy?.stop_date
                      ? beneficiary?.policy?.stop_date
                      : "N/A"}
                  </td>
                </tr>

                <tr className="d-flex flex-column">
                  <th scope="row" className="border-0">
                    Networks:
                  </th>
                  {beneficiary &&
                  beneficiary.policy &&
                  beneficiary.policy.product &&
                  beneficiary.policy.product.product_networks.length > 0 ? (
                    beneficiary.policy.product.product_networks.map(
                      productNetwork => (
                        <td
                          className="text-break text-primary d-block"
                          key={productNetwork.id}
                          onClick={() => {
                            setIsNetwrokModal(true)
                            setNetworkId(productNetwork.id)
                          }}
                        >
                          {productNetwork?.network?.name} (
                          {productNetwork?.benefit?.name} /{" "}
                          {productNetwork?.class?.name})
                        </td>
                      )
                    )
                  ) : (
                    <td>N/A</td>
                  )}
                  {/* <td>Full Network</td> */}
                </tr>
                <tr className="d-flex flex-column">
                  <th scope="row" className="border-0">
                    Benefits:
                  </th>
                  {beneficiary &&
                  beneficiary.co_participations &&
                  beneficiary.co_participations.length > 0 ? (
                    beneficiary.co_participations.map(item => (
                      <td key={item.id} className="text-break d-block">
                        <h5 className="font-size-13">{item.name}</h5>
                      </td>
                    ))
                  ) : (
                    <td>N/A</td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </CardBody>

      {isNetworkModal && (
        <NetworkContractsModal
          show={isNetworkModal}
          onCloseClick={() => setIsNetwrokModal(false)}
          networkId={networkId}
        />
      )}
    </aside>
  )
}

export default InsuranceDetailsCard
