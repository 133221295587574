import getChartColorsArray from "components/Common/ChartsDynamicColor";
import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";

const ClaimsAmount = ({ dataColors, KPI, iconClass }) => {
  const barChartColor = getChartColorsArray(dataColors);
  const benefits = KPI ? KPI.data?.map((item) => item?.benefits) : [];

  const maxBenefit = benefits[0]?.reduce((max, benefit) => {
    return benefit?.calculated_value > max?.calculated_value ? benefit : max;
  }, benefits[0][0] || { name: "", calculated_value: 0 });

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="h4 mb-4">{KPI?.name}</CardTitle>
          <div className="text-center mb-2">
            <div className="mb-4">
              <i
                style={{
                  fontSize: "60px",
                  color: "#556ee6",
                }}
                className="bx bx-shield-quarter mt-4"
              ></i>
            </div>
            <h3>{`$ ${
              maxBenefit?.calculated_value
                ? maxBenefit?.calculated_value?.toLocaleString("en-US")
                : 0
            }`}</h3>
            <p>{maxBenefit?.name}</p>
          </div>
          <div className="table-responsive mt-4 mb-4">
            <table className="table align-middle table-nowrap">
              <tbody>
                {benefits[0]?.map((benefit, index) => (
                  <tr key={index}>
                    <td style={{ width: "30%" }}>
                      <p className="mt-3 fs-6">{benefit?.name}</p>
                    </td>
                    <td style={{ width: "1%" }}>
                      <h5 className="mt-2">{`$ ${
                        benefit?.calculated_value
                          ? benefit?.calculated_value?.toLocaleString("en-US")
                          : 0
                      }`}</h5>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
      {/* <Card>
        <CardBody>
          <h4 className="card-title mt-4">{KPI?.name}</h4>
          <div>
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height="350"
            />
          </div>
        </CardBody>
      </Card> */}
    </React.Fragment>
  );
};

export default ClaimsAmount;
