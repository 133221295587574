import React, { Fragment } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { DateRangePicker, FormControll } from "../Common/Forms";

import { decryptText } from "utils";
import { buttonsTitles } from "common/data";
import CustomButton from "../Common/General/CustomButton";

function CardUser({ setDateRange, dateRange, handleClick, isLoading }) {
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  // main payer
  const mainPayer = JSON.parse(decryptText(localStorage.getItem("mainPayer")));

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row className="align-items-center g-1">
            <Col md={location.pathname.includes("/payer-dashboard") ? 5 : 8}>
              <div className="d-flex">
                <div className="flex-grow-1 align-self-center">
                  <div className="text-muted">
                    <p className="mb-2">Welcome Back</p>
                    <h5 className="mb-1">{authUser?.name}</h5>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={location.pathname.includes("/payer-dashboard") ? 4 : 4}>
              <DateRangePicker value={dateRange} setDateRange={setDateRange} />
            </Col>
            {location.pathname.includes("/payer-dashboard") && (
              <Fragment>
                <Col md={2}>
                  <FormControll
                    name="payer_id"
                    noLabel={true}
                    value={mainPayer?.name}
                    wrapperClassNames="mt-3"
                    readOnly={true}
                  />
                </Col>
                <Col md={1} className="mt-3">
                  <CustomButton
                    type="button"
                    className="btn btn-primary mt-1"
                    disabled={!mainPayer || isLoading ? true : false}
                    onClick={handleClick}
                    isSaving={isLoading}
                    title={buttonsTitles.submit}
                  />
                </Col>
              </Fragment>
            )}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default CardUser;
