/*
This is Hook is used to Extract the specific item 
from local storage
params => key => name of local stroage key (string)
params => enc => boolean if true mean that the localstroage value is encrypte false mean its a plain text (boolean)
*/

import React, { useEffect, useState } from "react";
import { decryptText } from "utils";

const useLocalStorage = (key, enc) => {
  const [item, setItem] = useState(null);

  useEffect(() => {
    const localStorageItem = localStorage.getItem(key);
    if (localStorageItem) {
      setItem(
        enc
          ? JSON.parse(decryptText(localStorageItem))
          : JSON.parse(localStorageItem)
      );
    }
  }, [key, enc]);

  return { localStorageItem: item };
};

export default useLocalStorage;
