import React, { useEffect, useState } from "react"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer"
import ACESImage from "assets/images/ACES_COLORED.png"

import JsBarcode from "jsbarcode"

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "col",
    backgroundColor: "#fff",
    width: "100%",
    height: "100%",
    paddingVertical: 10,
  },
  image: {
    // marginVertical: 15,
    marginTop: 15,
    width: 200,
    height: 100,
  },
  barCodeImage: {
    marginLeft: "auto",
    width: 200,
    height: 100,
  },
  barCode: {
    alignContent: "right",
    marginRight: 10,
    marginBottom: 10,
  },
  table: {
    flexDirection: "row",
    border: "2px solid #333",
    justifyContent: "space-between",
    marginHorizontal: 10,
  },
  tableSide: {
    flex: "1",
    paddingHorizontal: 5,
    borderRight: "2px solid #333",
  },
  tableSideHeader: {
    textAlign: "center",
    fontWeight: "900",
    marginBottom: "10px",
  },
  tableSideData: {
    fontSize: "14px",
    marginBottom: "5px",
  },
  boldText: {
    fontWeight: "ultrabold",
    fontSize: "16px",
  },
})

// Create Document Component
const ClaimPDFReport = ({ claim }) => {
  const [barCode, setBarCode] = useState("")

  useEffect(() => {
    // For Barcode
    if (claim.bar_code) {
      const canvas = document.createElement("canvas")
      JsBarcode(canvas, claim.bar_code, {
        // width: 1,
        // height: 20,
        // displayValue: true,
        // fontSize: 16,
      })
      setBarCode(canvas.toDataURL())
    } else {
      return
    }
  }, [claim.bar_code])

  return (
    <Document title="ACES | Medical Insurance">
      <Page size="A4" style={styles.page}>
        <Image src={ACESImage} style={styles.image} />
        {barCode ? (
          <Image src={barCode} style={styles.barCodeImage} />
        ) : (
          <Text style={styles.barCode}>Bar Code</Text>
        )}
        {/* <Barcode value={claim?.bar_code} /> */}
        <Text style={{ textAlign: "center", marginVertical: "10px" }}>
          In-Patient Claim
        </Text>

        {/* First Table */}
        <View style={styles.table}>
          <View style={styles.tableSide}>
            <Text style={styles.tableSideHeader}>Card Holder Information</Text>
            <Text style={styles.tableSideData}>{claim?.beneficiary?.name}</Text>
            <Text style={styles.tableSideData}>
              DOB: {claim?.beneficiary?.birth_date}
            </Text>
            <Text style={styles.tableSideData}>
              Code: {claim?.beneficiary?.code}
            </Text>
            <Text style={styles.tableSideData}>
              {claim?.beneficiary?.policy?.name}
            </Text>
            <Text style={styles.tableSideData}>
              Policy Holder: Mohamed Mansour Yousef
            </Text>
            <Text style={styles.tableSideData}>
              Valid: From
              <Text style={styles.boldText}>
                {" "}
                {claim?.beneficiary?.policy?.start_date}{" "}
              </Text>
              to
              <Text style={styles.boldText}>
                {" "}
                {claim?.beneficiary?.policy?.stop_date}{" "}
              </Text>
            </Text>
          </View>
          <View style={{ ...styles.tableSide, border: "none" }}>
            <Text style={styles.tableSideHeader}>Admission Information</Text>

            <Text style={styles.tableSideData}>
              Provider: {claim?.healthcare_provider?.first_name}
            </Text>
            <Text style={styles.tableSideData}>
              Date of Admission: {claim?.admission_date} Time: 20:08 Type:
              <Text style={{ textTransform: "uppercase" }}>
                Normal_confinment
              </Text>
            </Text>
            <Text style={styles.tableSideData}>
              Admission Class: {claim?.class?.name}
            </Text>
            <Text style={styles.tableSideData}>
              Admitting Physician: {claim?.physician?.name}
            </Text>

            <Text style={styles.tableSideData}>
              Estimation Cost: {claim?.estimation_amount}{" "}
              {claim?.currency?.name} {claim?.currency?.symbol}
            </Text>
          </View>
        </View>
        {/* Second Table */}
        <View
          style={{
            ...styles.table,
            marginTop: "10px",
            flexDirection: "column",
            paddingLeft: "10px",
            paddingTop: "10px",
          }}
        >
          <Text
            style={{
              ...styles.tableSideData,
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            <Text style={styles.boldText}>
              {claim?.healthcare_provider?.icd_version?.name} Codes:
            </Text>
            {claim?.medical_codes
              ?.filter(code => !code.discharge)
              .map((item, index) => (
                <Text key={item.id}>
                  {item?.name}{" "}
                  {index !==
                    claim?.medical_codes?.filter(code => !code.discharge)
                      .lenght -
                      1 && ","}
                </Text>
              ))}
          </Text>
          <Text
            style={{
              ...styles.tableSideData,
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            {claim?.healthcare_provider?.icd_version?.name} Description:
            Maligant neplasm of pancreas, part unspecified
          </Text>
        </View>

        {/* Third Table */}
        <View
          style={{
            ...styles.table,
            marginTop: "10px",
            flexDirection: "column",
            paddingLeft: "10px",
            paddingTop: "10px",
          }}
        >
          <Text style={{ ...styles.tableSideHeader, textAlign: "left" }}>
            Remarks and Details
          </Text>
          <Text
            style={{
              ...styles.tableSideData,
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            {claim?.authorization_notes}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

export default ClaimPDFReport
