import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { CompanyName } from "constants/TPA";

import Breadcrumbs from "components/Common/Breadcrumb";
import { Skeleton } from "antd";
import { compact } from "lodash";

const PageBreadCrumbAndTitle = ({
  title,
  pageType,
  titleAddOn,
  prefix,
  children,
  data,
  indexName,
  customTitle,
}) => {
  const { moduleName, loading } = useSelector((state) => ({
    moduleName: state.Module.name,
    loading: state.Module.loading,
  }));

  let newPageType;

  if (pageType && pageType.toLowerCase() === "add") {
    newPageType = " - Add Form";
  } else if (
    pageType &&
    (pageType.toLowerCase() === "edit" || pageType.toLowerCase() === "update")
  ) {
    newPageType = " - Edit Form";
  } else if (pageType && pageType.toLowerCase() === "view") {
    newPageType = " - View Form";
  } else if (pageType && pageType.toLowerCase() === "print") {
    newPageType = " - Print";
  } else {
    newPageType = "";
  }

  // useEffect(() => {
  //   document.title = `${
  //     loading
  //       ? "Loading..."
  //       : `${title ? title : moduleName} ${newPageType.replace("-", "")}`
  //   }`
  // }, [moduleName, loading, title, newPageType])

  useEffect(() => {
    if (data && indexName) {
      //split by | and remove all { or }
      const splitted = indexName
        .split("|")
        .map((item) => item.replaceAll(/{|}/g, "").trim());
      // access every key with the corresponding item in the array
      const final = splitted.map((item) => {
        if (item.includes(".")) {
          const splitDots = item.split(".");
          return data[splitDots[0]][splitDots[1]];
        } else {
          return data[item];
        }
      });
      // reconsturcted Again
      const reconstructed = compact(final).join(" | ");
      document.title = reconstructed;
    } else {
      document.title = `${
        loading
          ? "Loading..."
          : `${title ? title : moduleName} ${newPageType.replace("-", "")}`
      }`;
    }
  }, [
    JSON.stringify(data),
    indexName,
    moduleName,
    loading,
    title,
    newPageType,
  ]);
  return (
    // <div className={`${!pageType ? "d-none" : "d-block"}`}>
    <div>
      {loading ? (
        <Skeleton
          active
          round={true}
          title={true}
          paragraph={{
            rows: 0,
          }}
        />
      ) : (
        <Breadcrumbs
          title={`${prefix ? prefix : ""} ${title ? title : moduleName} ${
            titleAddOn ? titleAddOn : ""
          } ${newPageType}`}
          breadcrumbItem={
            customTitle
              ? customTitle
              : `${prefix ? prefix : ""} ${title ? title : moduleName} ${
                  titleAddOn ? `# ${titleAddOn}` : ""
                } ${newPageType}`
          }
        >
          {children}
        </Breadcrumbs>
      )}
    </div>
  );
};

export default PageBreadCrumbAndTitle;
