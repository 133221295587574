import React, { Fragment, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"

// UI Componets
import {
  TableContainer,
  TablePagination,
  TableSkeleton,
} from "components/Common"

// helper functions
import {
  activateBenefit,
  deactivateBenefit,
  deleteBenefit,
  getAllBenefits,
} from "helpers/CommonData/benefits"

// utility functions for API errors and Notification
import { apiErrorrHandler, NotificationMessage, generateColumns } from "utils"

// redux
import { useSelector } from "react-redux"

import { useHistory } from "react-router-dom"
import { Button, Col, Input, Row, Table } from "reactstrap"
import RowActions from "./RowActions"
import { DefaultColumnFilter } from "components/Common/filters"

const data = [
  {
    id: 1,
    name: "Product Name",
    parent_benefit: "In Patients",
    limitA: "100",
    limitB: "200",
    limitSP: "1800",
    excessA: "1800",
    excessB: "1800",
    excessSP: "1800",
    deductible: "1870",
    Watining: "1800",
    is_active: 1,
  },
  {
    id: 2,
    name: "Product Name",
    parent_benefit: "In Patients",
    limitA: "100",
    limitB: "200",
    limitSP: "1800",
    excessA: "1800",
    excessB: "1800",
    excessSP: "1800",
    deductible: "1870",
    Watining: "1800",
    is_active: 1,
  },
  {
    id: 3,
    name: "Product Name",
    parent_benefit: "In Patients",
    limitA: "100",
    limitB: "200",
    limitSP: "1800",
    excessA: "1800",
    excessB: "1800",
    excessSP: "1800",
    deductible: "1870",
    Watining: "1800",
    is_active: 1,
  },
  {
    id: 4,
    name: "Product Name",
    parent_benefit: "In Patients",
    limitA: "100",
    limitB: "200",
    limitSP: "1800",
    excessA: "1800",
    excessB: "1800",
    excessSP: "1800",
    deductible: "1870",
    Watining: "1800",
    is_active: 1,
  },
  {
    id: 5,
    name: "Product Name",
    parent_benefit: "In Patients",
    limitA: "100",
    limitB: "200",
    limitSP: "1800",
    excessA: "1800",
    excessB: "1800",
    excessSP: "1800",
    deductible: "1870",
    Watining: "1800",
    is_active: 1,
  },
  {
    id: 6,
    name: "Product Name",
    parent_benefit: "In Patients",
    limitA: "100",
    limitB: "200",
    limitSP: "1800",
    excessA: "1800",
    excessB: "1800",
    excessSP: "1800",
    deductible: "1870",
    Watining: "1800",
    is_active: 1,
  },
  {
    id: 7,
    name: "Product Name",
    parent_benefit: "In Patients",
    limitA: "100",
    limitB: "200",
    limitSP: "1800",
    excessA: "1800",
    excessB: "1800",
    excessSP: "1800",
    deductible: "1870",
    Watining: "1800",
    is_active: 1,
  },
  {
    id: 8,
    name: "Product Name",
    parent_benefit: "In Patients",
    limitA: "100",
    limitB: "200",
    limitSP: "1800",
    excessA: "1800",
    excessB: "1800",
    excessSP: "1800",
    deductible: "1870",
    Watining: "1800",
    is_active: 1,
  },
  {
    id: 10,
    name: "Product Name",
    parent_benefit: "In Patients",
    limitA: "100",
    limitB: "200",
    limitSP: "1800",
    excessA: "1800",
    excessB: "1800",
    excessSP: "1800",
    deductible: "1870",
    Watining: "1800",
    is_active: 1,
  },
]

const headers = [
  "id",
  "Name",
  "Parent Benefit",
  "Limit A",
  "Limit B",
  "Limit SP",
  "Excess A",
  "Excess B",
  "Excess SP",
  "Deductible %",
  "Waiting Period",
]
const EditableTable = () => {
  const history = useHistory()

  /*
   local states
  */

  // storing the columns that user want to hide

  const [benefits, setBenefits] = useState(data)
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  // const [showOptions, setShowOptions] = useState([])
  const [pagination, setPagination] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  // const [perPage, setPerPage] = useState(10)
  // const actionFields = useSelector(state => state?.Module?.actions?.fields)
  const moduleId = useSelector(state => state?.Module?.actions?.id)

  // const onChangeInSelect = event => {
  //   getBenefits({
  //     page: currentPage,
  //     per_page: event.target.value,
  //   })
  //   setPerPage(Number(event.target.value))
  // }

  // const handleDelete = async id => {
  //   try {
  //     await deleteBenefit(id)
  //     NotificationMessage("Success", "delete")
  //     getBenefits({
  //       page: 1,
  //       per_page: 10,
  //     })
  //   } catch (error) {
  //     const errorMessage = apiErrorrHandler(error)
  //     NotificationMessage("Error", errorMessage)
  //   }
  // }

  // const handleActivateDeactivate = async row => {
  //   const { id, is_active } = row
  //   console.log(row)
  //   console.log(id)
  //   try {
  //     if (is_active === 0) {
  //       await activateBenefit(id)
  //       NotificationMessage("Success", "activate")
  //     } else {
  //       await deactivateBenefit(id)
  //       NotificationMessage("Success", "deactivate")
  //     }
  //     getBenefits({
  //       page: 1,
  //       per_page: 10,
  //     })
  //   } catch (error) {
  //     const errorMessage = apiErrorrHandler(error)
  //     NotificationMessage("Error", errorMessage)
  //   }
  // }

  const handleClickUpdate = row => {
    const { id } = row
    history.push(`/benefits/edit/${id}`)
  }

  const handleChange = (e, id) => {
    const { name, value } = e.target
    const nameWithDot = name.includes(".")
    // const editData = editableBenefits.map(item =>
    const editData = benefits.map(item =>
      item.id === id && name
        ? nameWithDot
          ? {
              ...item,
              modified: 1,
              [name.split(".")[0]]: {
                ...item[name.split(".")[0]],
                [name.split(".")[1]]: value,
              },
            }
          : { ...item, modified: 1, [name]: value }
        : item
    )
    setBenefits(editData)
  }

  // const getBenefits = async ({
  //   page,
  //   per_page,
  //   sort_by,
  //   sort_type,
  //   search_value,
  //   search_keys,
  //   search_values,
  // }) => {
  //   try {
  //     setLoading(true)
  //     const benefitsRes = await getAllBenefits({
  //       page,
  //       per_page,
  //       sort_by,
  //       sort_type,
  //       search_value,
  //       search_keys,
  //       search_values,
  //     })
  //     setBenefits([...benefits, ...benefitsRes.items])
  //     setPagination({
  //       size: benefitsRes.size,
  //       page: benefitsRes.page,
  //       per_page: benefitsRes.per_page,
  //       total_pages: benefitsRes.total_pages,
  //       total: benefitsRes.total_size,
  //     })
  //     setLoading(false)
  //   } catch (error) {
  //     setLoading(false)
  //     const errorMessage = apiErrorrHandler(error)
  //     NotificationMessage("Error", errorMessage)
  //   }
  // }

  // useEffect(() => {
  //   getBenefits({ page: currentPage, per_page: 50 })
  // }, [currentPage])

  const handleSave = () => {
    const modifiedBenefits = benefits.filter(
      item => item.modified && item.modified === 1
    )
    console.log(modifiedBenefits)
  }

  return (
    <Fragment>
      {/* <Row className="mb-4 mt-3 justify-content-md-between justify-content-center">
        <Col md={1}>
          <select
            className="form-select"
            value={perPage}
            onChange={onChangeInSelect}
          >
            {[10, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
      </Row> */}

      <Row className="mb-4 mt-3 justify-content-md-between justify-content-center">
        {/* Search Bar */}
        {/* <Col
          md={7}
          // md={4}
        >
          <div className="search-box me-xxl-2 my-0 my-xxl-0 d-inline-block">
            <div className="position-relative">
              <label htmlFor="search-bar-0" className="search-label">
                <span id="search-bar-0-label" className="sr-only">
                  Search this table
                </span>
                <input
                  onChange={e => {
                    setSearchQuery(e.target.value)
                  }}
                  id="search-bar-0"
                  type="text"
                  className="form-control"
                  placeholder="Search ..."
                  value={searchQuery}
                />
              </label>
              <i className="bx bx-search-alt search-icon"></i>
            </div>
          </div>
        </Col> */}

        {/* Save Button */}
        <Col>
          <div className="text-sm-end">
            <Button
              type="button"
              color="success"
              className="btn me-2"
              onClick={handleSave}
              aria-label="Add New"
            >
              <i className="mdi mdi-plus me-1" />
              <span>Save Changes</span>
            </Button>
          </div>
        </Col>
      </Row>

      <div
        className="table-responsive react-table"
        style={{ overflow: "auto", height: "700px", display: "block" }}
      >
        <Table
          hover
          style={{
            position: "relative",
            // height: loading && "700px",
            height: "700px",
          }}
        >
          <thead className="table-light table-nowrap sticky-header">
            <tr>
              {headers.map(item => (
                <th key={item}>
                  {item}
                  {(item === "Name" || item === "Parent Benefit") && (
                    <Input
                      // id="ColumnFilter"
                      className="ColumnFilter mt-1"
                      placeholder={`search ...`}
                    />
                  )}
                </th>
              ))}
              {/* <th>Id</th>
              <th>Name</th>
              <th>Benefit Group</th> */}
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          {loading && currentPage === 1 ? (
            <div
              style={{
                position: "absolute",
                left: "0",
                right: "0",
              }}
            >
              <TableSkeleton />
            </div>
          ) : (
            <tbody>
              {benefits
                // .filter(
                //   item =>
                //     (item.name.toLowerCase().includes(searchQuery) ||
                //       item.group?.name?.toLowerCase().includes(searchQuery)) &&
                //     item
                // )
                .map(
                  ({
                    id,
                    name,
                    parent_benefit,
                    limitA,
                    limitB,
                    limitSP,
                    excessA,
                    excessB,
                    excessSP,
                    deductible,
                    Watining,
                    is_active,
                  }) => (
                    <tr key={id} style={{ height: "100px" }}>
                      <td colSpan="1">{id}</td>
                      <td>{name}</td>
                      <td>{parent_benefit}</td>
                      <td>
                        <input
                          id={`limitA-${id}`}
                          name="limitA"
                          type="text"
                          className="editable-table-input"
                          value={limitA}
                          onChange={e => handleChange(e, id)}
                        />
                      </td>
                      <td>
                        <input
                          id={`limitB-${id}`}
                          name="limitB"
                          type="text"
                          className="editable-table-input"
                          value={limitB}
                          onChange={e => handleChange(e, id)}
                        />
                      </td>
                      <td>
                        <input
                          id={`limitSP-${id}`}
                          name="limitSP"
                          type="text"
                          className="editable-table-input"
                          value={limitSP}
                          onChange={e => handleChange(e, id)}
                        />
                      </td>
                      <td>
                        <input
                          id={`excessA-${id}`}
                          name="excessA"
                          type="text"
                          className="editable-table-input"
                          value={excessA}
                          onChange={e => handleChange(e, id)}
                        />
                      </td>
                      <td>
                        <input
                          id={`excessB-${id}`}
                          name="excessB"
                          type="text"
                          className="editable-table-input"
                          value={excessB}
                          onChange={e => handleChange(e, id)}
                        />
                      </td>
                      <td>
                        <input
                          id={`excessSP-${id}`}
                          name="excessSP"
                          type="text"
                          className="editable-table-input"
                          value={excessSP}
                          onChange={e => handleChange(e, id)}
                        />
                      </td>
                      <td>
                        <input
                          id={`deductible-${id}`}
                          name="deductible"
                          type="text"
                          className="editable-table-input"
                          value={deductible}
                          onChange={e => handleChange(e, id)}
                        />
                      </td>
                      <td>
                        <input
                          id={`Watining-${id}`}
                          name="Watining"
                          type="text"
                          className="editable-table-input"
                          value={Watining}
                          onChange={e => handleChange(e, id)}
                        />
                      </td>
                      <td>{is_active === 1 ? "yes" : "No"}</td>
                      <td>
                        <RowActions
                          cellProps={{
                            row: { original: { id, name, is_active } },
                          }}
                          onDelete={() => handleDelete(id)}
                          onActivate={() =>
                            handleActivateDeactivate({
                              id: id,
                              is_active: is_active,
                            })
                          }
                          onClickUpdate={() => {
                            handleClickUpdate({ id: id })
                          }}

                          // onClickLog={() => handleClickLog(row)}
                        />
                      </td>
                    </tr>
                  )
                )}
              {/* <tr>
                <td colSpan={5} className="text-center">
                  <button
                    type="submit"
                    aria-label="add more content"
                    className="btn btn-info w-md"
                    disabled={loading || currentPage === pagination.total_pages}
                    onClick={() => setCurrentPage(prev => prev + 1)}
                  >
                    <span>Load More</span>
                    <i className="mdi mdi-rotate-3d-variant font-size-18 ms-2" />
                  </button>
                </td>
              </tr> */}
            </tbody>
          )}
        </Table>

        {/* Save Button */}

        {/* <div className="py-2">
          <div className="text-sm-end">
            <Button
              type="button"
              color="success"
              className="btn me-2"
              onClick={handleSave}
              aria-label="Add New"
            >
              <i className="mdi mdi-plus me-1" />
              <span>Save Changes</span>
            </Button>
          </div>
        </div> */}

        {/* <TablePagination
          pagination={pagination}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          getData={() =>
            getBenefits({
              page: currentPage,
              per_page: perPage,
            })
          }
        /> */}
      </div>

      <div className="py-4 me-4">
        <div className="text-sm-end">
          <Button
            type="button"
            color="success"
            className="btn me-2"
            onClick={handleSave}
            aria-label="Add New"
          >
            <i className="mdi mdi-plus me-1" />
            <span>Save Changes</span>
          </Button>
        </div>
      </div>
    </Fragment>
  )
}

EditableTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default EditableTable
