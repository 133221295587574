import React from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import ReactApexChart from "react-apexcharts"

import getChartColorsArray from "components/Common/ChartsDynamicColor"

const ClaimsNumber = ({ dataColors, KPI, ClaimCounts }) => {
  const apexsalesAnalyticsChartColors = getChartColorsArray(dataColors)

  //  return data from count-claims-by-months slug & sorting it
  const sortedKPI = ClaimCounts.data.sort((a, b) => {
    if (a.year !== b.year) {
      return a.year - b.year
    }
    return a.month_number - b.month_number
  })

  //return chart label from api
  const labels = sortedKPI.map(item => `${item.month_name} ${item.year}`)

  const options = {
    chart: { height: 280, type: "line", stacked: !1, toolbar: { show: !1 } },
    stroke: { width: [0, 2, 5], curve: "smooth" },
    plotOptions: { bar: { columnWidth: "20%", endingShape: "rounded" } },
    colors: apexsalesAnalyticsChartColors,
    fill: {
      gradient: {
        inverseColors: !1,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    // labels: [
    //   "Jan",
    //   "Feb",
    //   "Mar",
    //   "Apr",
    //   "May",
    //   "Jun",
    //   "Jul",
    //   "Aug",
    //   "Sep",
    //   "Oct",
    //   "Nov",
    // ],
    labels: labels,
    markers: { size: 0 },
    yaxis: { min: 0 },
  }

  const series = [
    {
      name: "Claims",
      type: "column",
      data: sortedKPI.map(item => item.count),
    },
  ]

  //get sum claims counts
  const totalClaims = KPI.data.reduce((total, item) => total + item.count, 0)

  return (
    <React.Fragment>
      <Row>
        <Col lg="3">
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Number of Claims</CardTitle>
              <div className="container">
                <Row className="d-flex justify-content-center">
                  <Col md={8}>
                    <div className="mb-4 text-center">
                      <div className="mb-2 align-self-center ">
                        <i
                          style={{ fontSize: "50px" }}
                          className="bx bx-shield-quarter text-primary"
                        ></i>
                      </div>
                      <div className="mb-2 font-size-18 fw-bold ">
                        {totalClaims}
                      </div>
                      <div className=" text-muted mb-2">Claims</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={7}>
                    {KPI.data &&
                      KPI.data.map((item, index) => (
                        <div
                          key={item.benefit_slug}
                          className="mb-3 d-flex justify-content-between"
                        >
                          <div>{item.benefit_name}</div>
                          <div className="font-size-15 fw-bold ">
                            {item.count}
                          </div>
                        </div>
                      ))}
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="9">
          <Card>
            <CardBody>
              <CardTitle className="mb-3">Claims By Months </CardTitle>
              <Row>
                <Col lg="12">
                  <div id="line-chart">
                    <ReactApexChart
                      series={series}
                      options={options}
                      type="line"
                      height={300}
                      className="apex-charts"
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ClaimsNumber
