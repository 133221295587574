import React, { Fragment, useMemo, useState } from "react";

//i18n
import { withTranslation } from "react-i18next";

// UI Components
import {
  FormControll,
  FormModal,
  ModalSpinner,
  SearchSelect,
} from "../../../components/TP/Common/Forms";

import { Alert } from "reactstrap";

// utility function for error
import { fieldFinder, isInputInvalid } from "utils/";
import { useSelector } from "react-redux";
import {
  claimAdmissionDetailsId,
  claimDetailsModuleId,
} from "utils/modulesIds";
import * as URLs from "helpers/url_helper";

const ChangeClaimStatusModal = ({
  type,
  validation,
  isOpen,
  setIsOpen,
  OnConfirm,
  isSaving,
}) => {
  // states
  const [error, setError] = useState("");

  // desturcutre validation object
  const { values } = validation;

  const { fields: inputs, loading } = useSelector((state) => ({
    fields: state?.Module?.subModules
      ?.find((item) => item.id === claimDetailsModuleId)
      ?.sub_modules?.find((item) => item.id === claimAdmissionDetailsId)
      ?.fields,
    loading: state?.Module?.loading,
  }));

  const { isRequiredReason } = useMemo(() => {
    const requiredReasonsTypes = [
      "reopen",
      "routed",
      "unprocessed",
      "update-status",
      "void",
      "pending",
    ];
    return {
      isRequiredReason: requiredReasonsTypes.includes(type) ? true : false,
    };
  }, [type]);

  return (
    <FormModal
      show={isOpen}
      onCloseClick={() => setIsOpen(false)}
      onSaveClick={() => {
        OnConfirm();
        setIsOpen(false);
      }}
      modalTitle={
        <span className="text-capitalize">{`${type.replaceAll(
          "-",
          " "
        )} Claim`}</span>
      }
      isSaving={isSaving}
      disableSave={
        (isRequiredReason && !values.change_reason_id) ||
        (type === "routed" && !values.routed_to_user_id)
          ? true
          : false
      }
      disableTooltip={
        isRequiredReason && !values.change_reason_id
          ? "Please Select Reason"
          : ""
      }
    >
      {loading ? (
        <ModalSpinner />
      ) : (
        <form onSubmit={(e) => e.preventDefault()}>
          {/* API Error Message For Re-Insurer Module */}

          {error ? <Alert color="danger">{error}</Alert> : null}

          {inputs && inputs.length > 0 && (
            <Fragment>
              {/* Re-Insurer Name Label & Input  */}
              {type === "forward" && (
                <Fragment>
                  <SearchSelect
                    id="711"
                    name="routed_user_id"
                    inputField={{
                      ...fieldFinder(inputs, 711),
                      predefined_value: null,
                      label_name: "",
                      is_required: 1,
                    }}
                    label="User"
                    placeholder="Select User"
                    value={validation.values.routed_user_id}
                    onChange={validation.setFieldValue}
                    onBlur={validation.setFieldTouched}
                    mappingValue="first_name"
                    mappingLabel="first_name"
                    generateCustomLabels={(item) =>
                      `${item.first_name} ${item.last_name}`
                    }
                    url={URLs.USERS}
                    urlAddOns={{
                      access_type_id: "1",
                      job_role_id: 50,
                      is_active: 1,
                    }}
                    generateDisabledOptions={(option) => option.is_active === 0}
                    invalid={isInputInvalid(validation, "routed_user_id")}
                    error={validation.errors.routed_user_id}
                    wrapperClassNames="mb-3"
                  />

                  <SearchSelect
                    id="1520"
                    name="priority_id"
                    inputField={fieldFinder(inputs, 1520)}
                    value={validation.values.priority_id}
                    onChange={validation.setFieldValue}
                    onBlur={validation.setFieldTouched}
                    mappingLabel="name"
                    mappingValue="id"
                    url={`${URLs.PREDEFINEDS}/${
                      fieldFinder(inputs, 1520)?.predefined_value?.id
                    }`}
                    invalid={isInputInvalid(validation, "priority_id")}
                    error={validation.errors.priority_id}
                    wrapperClassNames="mb-3"
                    disableViewPage={true}
                  />

                  <SearchSelect
                    id="1527"
                    name="change_reason_id"
                    inputField={{
                      ...fieldFinder(inputs, 1527),
                      predefined_value: null,
                      is_required: 1,
                    }}
                    value={validation.values.change_reason_id}
                    onChange={validation.setFieldValue}
                    onBlur={validation.setFieldTouched}
                    mappingLabel="name"
                    mappingValue="id"
                    url={`${URLs.PREDEFINEDS}?parent_slug=${
                      fieldFinder(inputs, 1527)?.predefined_value?.slug
                    }`}
                    invalid={isInputInvalid(validation, "change_reason_id")}
                    error={validation.errors.change_reason_id}
                    wrapperClassNames="mb-3"
                    disableViewPage={true}
                  />

                  <FormControll
                    id="5666"
                    name="status_note"
                    type="textarea"
                    label="Notes"
                    placeholder="Enter Notes"
                    value={validation.values.status_note}
                    // inputField={fieldFinder(inputs, 407)}
                    {...validation.getFieldProps("status_note")}
                    invalid={isInputInvalid(validation, "status_note")}
                    error={validation.errors.status_note}
                    wrapperClassNames="mb-3"
                    disableViewPage={true}
                  />
                </Fragment>
              )}
              {type === "declined" && (
                <SearchSelect
                  id="711"
                  name="change_reason_id"
                  inputField={fieldFinder(inputs, 711)}
                  value={validation.values.change_reason_id}
                  onChange={validation.setFieldValue}
                  onBlur={validation.setFieldTouched}
                  mappingLabel="name"
                  mappingValue="id"
                  url={`${URLs.PREDEFINEDS}/${
                    fieldFinder(inputs, 711)?.predefined_value?.id
                  }`}
                  invalid={isInputInvalid(validation, "change_reason_id")}
                  error={validation.errors.change_reason_id}
                  wrapperClassNames="mb-3"
                />
              )}
              {type === "pending" && (
                <SearchSelect
                  id="710"
                  name="change_reason_id"
                  inputField={fieldFinder(inputs, 710)}
                  value={validation.values.change_reason_id}
                  onChange={validation.setFieldValue}
                  onBlur={validation.setFieldTouched}
                  mappingLabel="name"
                  mappingValue="id"
                  url={`${URLs.PREDEFINEDS}/${
                    fieldFinder(inputs, 710)?.predefined_value?.id
                  }`}
                  invalid={isInputInvalid(validation, "change_reason_id")}
                  error={validation.errors.change_reason_id}
                  wrapperClassNames="mb-3"
                />
              )}
              {/* When Click unprossed (change status to received) */}
              {type === "unprocessed" && (
                <SearchSelect
                  id="787"
                  name="change_reason_id"
                  inputField={fieldFinder(inputs, 787)}
                  value={validation.values.change_reason_id}
                  onChange={validation.setFieldValue}
                  onBlur={validation.setFieldTouched}
                  mappingLabel="name"
                  mappingValue="id"
                  url={`${URLs.PREDEFINEDS}/${
                    fieldFinder(inputs, 787)?.predefined_value?.id
                  }`}
                  invalid={isInputInvalid(validation, "change_reason_id")}
                  error={validation.errors.change_reason_id}
                  wrapperClassNames="mb-3"
                />
              )}
              {type === "reopen" && (
                <SearchSelect
                  id="1186"
                  name="change_reason_id"
                  inputField={fieldFinder(inputs, 1186)}
                  value={validation.values.change_reason_id}
                  onChange={validation.setFieldValue}
                  onBlur={validation.setFieldTouched}
                  mappingLabel="name"
                  mappingValue="id"
                  url={`${URLs.PREDEFINEDS}/${
                    fieldFinder(inputs, 1186)?.predefined_value?.id
                  }`}
                  invalid={isInputInvalid(validation, "change_reason_id")}
                  error={validation.errors.change_reason_id}
                  wrapperClassNames="mb-3"
                />
              )}
            </Fragment>
          )}
        </form>
      )}
    </FormModal>
  );
};

export default withTranslation()(ChangeClaimStatusModal);
