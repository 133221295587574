import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

import { productionDashboardID, dashboardsID } from "utils/modulesIds"
import PageBreadCrumbAndTitle from "components/TP/Common/General/PageBreadCrumbAndTitle"
import { CardUser, FOBChart, KPIInfoCard } from "components/TP/dashboards"
import { showDashboard } from "helpers/General/dashboards_helper"
import { NotificationMessage, apiErrorrHandler } from "utils"
import { useSelector } from "react-redux"
import { APIDateFormat, CompanyName } from "constants/TPA"
import moment from "moment"
import dayjs from "dayjs"
import { ModalSpinner } from "components/TP/Common/Forms"

const ProductionDashboard = props => {
  const [dashboardKPIs, setDashboardKPIs] = useState([])
  const [dateRange, setDateRange] = useState({
    start_date: dayjs().add(-7, "d").format(APIDateFormat),
    end_date: dayjs().format(APIDateFormat),
  })
  const [isLoading, setIsLoading] = useState(true)

  const { module, loading } = useSelector(state => ({
    module: state.Sidebar?.modules
      ?.find(item => item.id === dashboardsID)
      ?.sub_modules?.find(item => item.id === productionDashboardID),
    loading: state.Sidebar?.loading,
  }))

  const dataColors =
    '["--bs-primary", "--bs-success", "--bs-danger" , "--bs-secondary"]'

  const getDashboard = async (dashboardId, dates) => {
    setIsLoading(true)
    try {
      const response = await showDashboard(dashboardId, dates)
      setDashboardKPIs(response.item)
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getDashboard(productionDashboardID, {
      start_date: moment(dateRange.start_date).format(APIDateFormat),
      end_date: moment(dateRange.end_date).format(APIDateFormat),
    })
  }, [dateRange.start_date, dateRange.end_date])

  const KPIsIcons = [
    {
      slug: "count_active_beneficiaries",
      iconClass: "mdi mdi-account-multiple-check",
    },
    {
      slug: "count_inactive_beneficiaries",
      iconClass: "mdi mdi-account-multiple-remove",
    },
    {
      slug: "count_new_beneficiaries",
      iconClass: "mdi mdi-account-multiple-plus",
    },
    {
      slug: "count_blacklisted_beneficiaries",
      iconClass: "mdi mdi-account-alert",
    },
    {
      slug: "count_active_policies",
      iconClass: "bx bx-copy-alt",
    },
    {
      slug: "count_new_policies",
      iconClass: "mdi mdi-book-plus",
    },
    {
      slug: "count_expired_policies",
      iconClass: "mdi mdi-book-remove",
    },
    {
      slug: "count-blacklisted-healthcare-providers",
      iconClass: "bx bxs-user-detail",
    },
    {
      slug: "count-blacklisted-physicians",
      iconClass: "bx bxs-user-detail",
    },
    {
      slug: "count-blacklisted-brokers",
      iconClass: "bx bxs-user-detail",
    },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <PageBreadCrumbAndTitle title={module?.title} />
          {/* Rounded Corners White Card and Table inside it */}
          <Row>
            {/* Welcome User Card */}
            <CardUser setDateRange={setDateRange} dateRange={dateRange} />
          </Row>

          <Row>
            {isLoading ? (
              <ModalSpinner color="primary" />
            ) : (
              dashboardKPIs.length > 0 &&
              dashboardKPIs.map((kpi, key) => (
                <Col md="3" key={"_col_" + key}>
                  <KPIInfoCard
                    name={kpi.name}
                    count={kpi.data.count}
                    iconClass={
                      KPIsIcons.find(icon => icon.slug === kpi.slug)?.iconClass
                    }
                  />
                </Col>
              ))
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ProductionDashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ProductionDashboard)
