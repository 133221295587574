/* Paths */
import { dashboardPaths } from "common/data/routePaths"
/*
Components
*/
// claim dashboard
import ClaimDashboard from "pages/Dashboard/ClaimDashboard"
import PayerDashboard from "pages/Dashboard/PayerDashboard"
// Production Dashboard
import ProductionDashboard from "pages/Dashboard/ProductionDashboard"
import StaticDashboard from "pages/Dashboard/StaticDashboard"

const { claimDashboard, productionDashboard, payerDashboard } = dashboardPaths
export const DashboardsRoutes = [
  // Claim Dashboard
  { path: claimDashboard, component: ClaimDashboard },
  // Production Dashboard
  { path: productionDashboard, component: ProductionDashboard },
  //payer Dashboard
  { path: payerDashboard, component: PayerDashboard },
  //payer Dashboard
  { path: "/main-dashboard", component: StaticDashboard },
]
