import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
const SingleRowAction = ({
  to,
  onClick,
  record,
  actionName,
  color,
  iconClass,
  target,
  disabeld,
  iconSize = "font-size-18",
}) => {
  return (
    <Fragment>
      {onClick && !to ? (
        <button
          color="link"
          type="button"
          className={`btn btn-transparent p-0 border-0 ${
            color ? color : "text-secondary"
          }`}
          onClick={onClick}
          disabled={disabeld ? disabeld : false}
        >
          <i
            className={`${iconClass} ${iconSize}`}
            id={`${actionName.replaceAll(" ", "")}tooltip${record?.id}`}
          />
          <UncontrolledTooltip
            placement="top"
            target={`${actionName.replaceAll(" ", "")}tooltip${record?.id}`}
          >
            {actionName}
          </UncontrolledTooltip>
        </button>
      ) : (
        <Link
          to={to}
          className={`${
            disabeld ? "pe-none opacity-50" : "pe-auto opacity-100"
          } ${color ? color : "text-secondary"}`}
          target={target ? target : "_self"}
        >
          <i
            className={`${iconClass} font-size-18`}
            id={`${actionName.replaceAll(" ", "")}tooltip${record?.id}`}
          />
          <UncontrolledTooltip
            placement="top"
            target={`${actionName.replaceAll(" ", "")}tooltip${record?.id}`}
          >
            {actionName}
          </UncontrolledTooltip>
        </Link>
      )}
    </Fragment>
  );
};

export default SingleRowAction;
