import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

// redux

import { useDispatch, useSelector } from "react-redux"
import { getActions as onGetActions } from "store/actions"

import { benefitsModuleID, commonDataId } from "utils/modulesIds"
import EditableTable from "components/TP/Common/Tables/EditableTable2"

const Test = props => {
  //meta title
  document.title = "ACES | Test"

  const { modules } = useSelector(state => ({
    modules: state.Sidebar.modules,
  }))

  // initialize useDispatch hook
  const dispatch = useDispatch()

  const testModule =
    modules &&
    modules.find(item => item.id === commonDataId) &&
    modules
      .find(item => item.id === commonDataId)
      .sub_modules.find(item => item.id === benefitsModuleID)

  useEffect(() => {
    dispatch(onGetActions(benefitsModuleID))
  }, [onGetActions])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={testModule?.title}
            breadcrumbItem={testModule?.title}
          />

          {/* Rounded Corners White Card and Table inside it */}
          <Row>
            <Col xs="12">
              {/* Card To Ouptut White Card Around The Table */}
              <Card>
                <CardBody>
                  <EditableTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Test.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Test)
